import type { MixpanelAppEventMap } from './helpers/app-helpers';
import type { MixpanelAuthEventMap } from './helpers/auth-helpers';
import type { MixpanelBillingEventMap } from './helpers/billing-helpers';
import type { MixpanelDiscoverEventMap } from './helpers/discover-helpers';
import type { MixpanelErrorEventMap } from './helpers/error-helpers';
import type { MixpanelSettingEventMap } from './helpers/setting-helpers';
import type { MixpanelShardEventMap } from './helpers/shard-helpers';
import type { MixpanelSquadEventMap } from './helpers/squad-helpers';
import type { MixpanelVideoEventMap } from './helpers/video-helpers';

interface Sources {
  'Video Replay Page': void;
  'Video Management Drawer': void;
  'Video Context Menu': void;
  'Video Card': void;
  'Selection Tools': void;
  'Folder Context Menu': void;
  'Clip Created Dialog': void;
  'Video Merge Page': void;
  'Onboarding': void;
  'Login Button': void;
  'Upgrade Button': void;
  'Upload Button': void;
  'Share Button': void;
  'Copy Referral Link': void;
  'Discover Upvote Button': void;
  'Discover Submit Clip Button': void;
  'Discover Tab': void;
  'Discover Leaderboard Item': void;
  'Discover Filter': void;
  'Discover Feed': void;
  'Discover Report Submission Button': void;
  'Discover Submissions': void;
  'Discover Submit Clip Dialog': void;
  'Settings Page': void;
  'Secondary Window': void;
  'Game Launch': void;
  'Game Exit': void;
  'App Launch': void;
  'Tray Icon': void;
  'Tray Menu Item': void;
  'Upload Queue': void;
  'Share Dialog': void;
  'Public': void;
  'Elite Badge': void;
  'Add Clips Button': void;
  'Add Montage Button': void;
  'Statistics Button': void;
  'Bookmark Comment Button': void;
  'Right Panel Tab Button': void;
  'Automatic': void;
  'Initial': void;
  'Hotkey': void;
  'Capture Mode Notification': void;
  'Team Video Replay Page': void;
  'Team Video Context Menu': void;
  'Squad Button': void;
}

export type MixpanelEventMap =
  & MixpanelAppEventMap
  & MixpanelAuthEventMap
  & MixpanelBillingEventMap
  & MixpanelDiscoverEventMap
  & MixpanelErrorEventMap
  & MixpanelSettingEventMap
  & MixpanelShardEventMap
  & MixpanelSquadEventMap
  & MixpanelVideoEventMap
;

export type MixpanelEventName = keyof MixpanelEventMap;
export type MixpanelEventParams<Name extends keyof EventMap, EventMap = MixpanelEventMap> = [Name, EventMap[Name]];
export type MixpanelEventSource<T extends keyof Sources> = T extends keyof Sources ? T: never;

export interface MixpanelSuperProperties {
  'Ad-free': boolean;
  'App Version': string;
  'Is Signed In': boolean;
  'Commit SHA': string;
}

export type MixpanelSuperPropertyName = keyof MixpanelSuperProperties;

export interface MixpanelUserProperties {
  'Ad-free': boolean;
  'App Version': string;
  'Commit SHA': string;
  'Clips Created': string;
  'Delete Original': boolean;
  'Highlights Created': string;
  'Language': string;
  'Videos Recorded': number;
  'Videos Social Shared': number;
  'Videos Uploaded': number;
  'Primary Color Set': boolean;
  'Secondary Color Set': boolean;
  'Background Image Set': string;
  'Secondary Window Disabled': boolean;
  'Light Mode': boolean;
  'Is Signed In': boolean;
  'Theme': string;
  '$email': string;
  '$name': string;
}

export type MixpanelUserPropertyName = keyof MixpanelUserProperties;

export interface MixpanelUserPropertiesOnce {
  'Referrer Id': ID;
}

export const mpProps = {
  eventSource: 'Event Source',
  recordingType: 'Recording Type',
  contentDuration: 'Content Duration',
  gameClassId: 'Game Class Id',
  queueId: 'Queue Id',
  encoder: 'Encoder',
  bitrate: 'Bitrate',
  resolution: 'Resolution',
  framerate: 'Framerate',
  bookmarksCreated: 'Bookmarks Created',
  commentLabelsCreated: 'Comment Labels Created',
  notepadUsed: 'Notepad Used',
  videoCount: 'Video Count',
  videoType: 'Video Type',
  folderCount: 'Folder Count',
  currentVersion: 'Current Version',
  previousVersion: 'Previous Version',
  registrationDate: 'Registration Date',
  registrationMethod: 'Registration Method',
  pcSpecLevel: 'PC Spec Level',
  recordingPreset: 'Recording Settings Preset',
  challengeId: 'Challenge Id',
  submissionId: 'Submission Id',
  shareDestination: 'Share Destination',
  clipRangeModified: 'Clip Range Modified',
  settingPath: 'Setting Path',
  settingValue: 'Setting Value',
  windowName: 'Window Name',
  sentToTray: 'Sent to Tray',
  openedOnPrimary: 'Opened on Primary',
  bookmarkAttached: 'Bookmark Attached',
  clipCombined: 'Clip Combined',
  segmentCount: 'Segment Count',
  planName: 'Plan Name',
  durationInterval: 'Duration Interval',
  windowWidth: 'Window Width',
  windowHeight: 'Window Height',
  bookmarkType: 'Bookmark Type',
  error: 'Error',
  managementStrategy: 'Management Strategy',
  devicePixelRatio: 'Device Pixel Ratio',
  shards: 'Shards',
  prizeId: 'Prize Id',
  dailyCap: 'Daily Cap',
  monthlyCap: 'Monthly Cap',
  language: 'Language',
  signInDialogAction: 'Sign In Dialog Action',
  filesize: 'File Size',
  streamResult: 'Stream Result',
  inputOverlayEnabled: 'Input Overlay Enabled',
  products: 'Products',
  promotionName: 'Promotion Name',
  tabName: 'Tab Name',
  uploadOption: 'Upload Option',
  deleteOriginal: 'Delete Original',
  enabledGameClassIds: 'Enabled Game Class Ids',
  disabledGameClassIds: 'Disabled Game Class Ids',
  gameAutoLaunchSettings: 'Game Auto Launch Settings',
  customTheme: 'Custom Theme',
  backgroundImage: 'Background Image',
  settings: 'Settings',
  captureModeSetting: 'Capture Mode Setting',
  nativeEmoji: 'Native Emoji',
  customEmoji: 'Custom Emoji',
  pictureSpecified: 'Picture Specified',
  bannerSpecified: 'Banner Specified',
  folderName: 'Folder Name',
  teamName: 'Team Name',
  expiry: 'Expiry',
  globalRole: 'Global Role',
  customRole: 'Custom Role',
  useLimit: 'Use Limit',
  videoId: 'Video Id',
  reactionCount: 'Reaction Count',
  isOwner: 'Is Owner',
  webcamEnabled: 'Webcam Enabled',
  oAuthprovider: 'OAuth Provider',
  backupRestoreStrategy: 'Backup Restore Strategy',
  conflictResolutionStrategy: 'Conflict Resolution Strategy',
} as const;
