import { partition } from '@insights-gaming/utils';
import { createSelector } from '@reduxjs/toolkit';
import { addDays } from 'date-fns';
import { get } from 'lodash';

import { BASE2_B_PER_GB } from '@/app/constants';
import { RootReducer } from '@/app/rootReducer.app';
import { getMergedSettings, getRecycleBinDurationDays } from '@/features/settings/setting-selector';
import { settingPath } from '@/features/settings/setting-slice';
import { VideoComparators } from '@/utils/compare';
import { isExistent } from '@/utils/guard';
import { getVideoUuidFromProps, getVideoUuidsFromProps } from '@/utils/props-selector';
import { VideoHelper } from '@/utils/video';

import { checkIsAnyMaxProgressProcessingVideo, checkIsProcessingMergeVideo, checkIsStreamVideo, Video, VideoLibraryFolder } from './video-library-slice';

export const getVideos = (state: RootReducer) => state.videoLibrary.videos;
export const getRecycledVideos = (state: RootReducer) => state.videoLibrary.recycledVideos;
export const getNewVideos = (state: RootReducer) => state.videoLibrary.newVideos;
export const getMontageQueue = (state: RootReducer) => state.videoLibrary.montageQueue;
export const getFavoritedVideos = (state: RootReducer) => state.videoLibrary.favoritedVideos;
export const getParentDict = (state: RootReducer) => state.videoLibrary.parentDict;

const getIgnoreLatestFromProps = (_: RootReducer, props: { ignoreLatest: boolean }) => props.ignoreLatest;
const getFolderUuidFromProps = (_: RootReducer, props: { folderUuid: UUID | undefined }) => props.folderUuid;
const getFolderUuidsFromProps = (_: RootReducer, props: { folderUuids: UUID[] }) => props.folderUuids;
const getItemUuidFromProps = (_: RootReducer, props: { itemUuid: UUID }) => props.itemUuid;
const getRecycledFromProps = (_: RootReducer, props: { recycled: boolean }) => props.recycled;

export const getFolders = (state: RootReducer) => state.videoLibrary.folders;
export const getRecycledFolders = (state: RootReducer) => state.videoLibrary.recycledFolders;
export const getAvailableFreeSpace = (state: RootReducer) => state.videoLibrary.availableFreeSpace;

export const getVideosAsArray = createSelector(
  [getVideos],
  (dict) => Object.values(dict).filter(isExistent),
);

export const getVideoAsArraySortByCreated = createSelector(
  [getVideosAsArray],
  (videoArray) => Array.from(videoArray).sort(VideoComparators.created.desc),
);

export const getFilteredUserLastCreatedVideo = createSelector(
  [getVideoAsArraySortByCreated],
  (videoArray) => videoArray.filter(checkIsStreamVideo)[1],
);

export const getPartitionedVideosAsArray = createSelector(
  [getVideosAsArray, getRecycledVideos],
  (videos, recycled) => partition(videos, video => !recycled[video.uuid]),
);

const getFavoritedUuidsAsArray = createSelector(
  [getFavoritedVideos],
  (dict) => Object.keys(dict),
);

export const getVideoByUuid = (state: RootReducer, uuid: UUID): Video | undefined => state.videoLibrary.videos[uuid];

export const makeGetVideoByUuid = () => createSelector(
  [getVideoByUuid],
  (video) => video,
);

export const getVideosMissingDict = (state: RootReducer) => state.videoLibrary.videoMissing;
const getVideoMissingByUuid = (state: RootReducer, uuid: UUID) => state.videoLibrary.videoMissing[uuid];

export const getMissingVideoUuidsAsArray = createSelector(
  [getVideosMissingDict],
  (missing) => {
    return Object.entries(missing)
      .reduce<UUID[]>((missingUuids, [uuid, missing]) => {
        if (missing) {
          missingUuids.push(uuid);
        }
        return missingUuids;
      }, []);
  },
);

export const getNonMissingSortedVideos = createSelector(
  [getPartitionedVideosAsArray, getVideosMissingDict],
  ([nonRecycled], missing) => {
    const nonMissing = nonRecycled.filter(video => !missing[video.uuid]);
    const sorted = nonMissing.sort(VideoComparators.created.desc);
    return sorted;
  },
);

export const makeGetNextVideo = () => createSelector(
  [getNonMissingSortedVideos, getVideoByUuid],
  (sortedVideos, video) => {
    if (!video) {
      return undefined;
    }
    return sortedVideos.find(v => v.uuid !== video.uuid && v.created <= video.created);
  },
);

export const makeGetVideoMissingByUuid = () => createSelector(
  [getVideoMissingByUuid],
  (missing) => missing,
);

export const makeGetIsNewVideoByUuid = () => createSelector(
  [getNewVideos, getVideoUuidFromProps],
  (newVideos, uuid) => newVideos[uuid],
);

export const getVideoGameClassIds = createSelector(
  [getVideosAsArray],
  (videos: Video[]) => Array.from(videos.reduce((p, c) => {
    if (c.gameClassId) {
      p.add(VideoHelper.getGameClassIdLike(c));
    }
    return p;
  }, new Set<number | string>())),
);

export const makeGetVideosByUuids = () => createSelector(
  [getVideos, getVideoUuidsFromProps],
  (dict, videoUuids) => videoUuids.map(uuid => dict[uuid]).filter(isExistent),
);

export const getFavoritedVideosAsArray = createSelector(
  [getVideos, getFavoritedUuidsAsArray],
  (dict, videoUuids) => {
    return videoUuids.map(uuid => dict[uuid]).filter(isExistent);
  },
);

export const getVideoLibrarySize = createSelector(
  [getPartitionedVideosAsArray],
  (partitionedVideos) => {
    const [nonRecycledSize, recycledVideosSize] = partitionedVideos
      .map(
        arr => arr.reduce((p, c) => p + c.size ?? 0, 0),
      );

    return [nonRecycledSize, recycledVideosSize];
  },
);

export const getVideoLibraryCount = createSelector(
  [getPartitionedVideosAsArray],
  (partitionedVideos) => partitionedVideos.map(videos => videos.length),
);

export const makeGetOldestVideosOverThresholdAsUuidArray = () => createSelector(
  [getPartitionedVideosAsArray, getVideoLibrarySize, getFavoritedVideos, getMergedSettings, getIgnoreLatestFromProps],
  ([unsortedVideos], [totalSize], favoritedVideos, settings, ignoreLatest) => {
    const thresholdGB = get(settings, settingPath.videoLibrary.filesizeWarning.threshold.$path);
    const thresholdB = thresholdGB * BASE2_B_PER_GB;
    const amountOver = totalSize - thresholdB;
    const sorted = unsortedVideos.slice(0).sort(VideoComparators.created.asc);

    let videosToDeleteSize = 0;
    const videosToDelete: UUID[] = [];

    for (let i = 0; i < sorted.length - +ignoreLatest && videosToDeleteSize <= amountOver; i++) {
      if (!favoritedVideos[sorted[i].uuid]) {
        videosToDelete.push(sorted[i].uuid);
        videosToDeleteSize += sorted[i].size;
      }
    }

    return videosToDelete;
  },
);

export const getOldestVideosOverThresholdAsUuidArray =  makeGetOldestVideosOverThresholdAsUuidArray();

export const makeGetPotentialVideosOverThresholdAsUuidArray = () => createSelector(
  [getPartitionedVideosAsArray, getVideoLibrarySize, getFavoritedVideos, getMergedSettings, getIgnoreLatestFromProps, getVideoUuidsFromProps],
  ([unsortedVideos], [totalSize], favoritedVideos, settings, ignoreLatest, unfavoritedUuids) => {
    const thresholdGB = get(settings, settingPath.videoLibrary.filesizeWarning.threshold.$path);
    const thresholdB = thresholdGB * BASE2_B_PER_GB;
    const amountOver = totalSize - thresholdB;
    const sorted = unsortedVideos.slice(0).sort(VideoComparators.created.asc);

    let videosToDeleteSize = 0;
    const videosToDelete: UUID[] = [];

    for (let i = 0; i < sorted.length - +ignoreLatest && videosToDeleteSize <= amountOver; i++) {
      if (!favoritedVideos[sorted[i].uuid] || unfavoritedUuids.includes(sorted[i].uuid)) {
        videosToDelete.push(sorted[i].uuid);
        videosToDeleteSize += sorted[i].size;
      }
    }

    return videosToDelete;
  },
);

export const makeGetIsVideoFavorited = () => createSelector(
  [getFavoritedVideos, getVideoUuidFromProps],
  (favoritedVideos, uuid) => !!favoritedVideos[uuid],
);

export const makeGetAreVideosFavorited = () => createSelector(
  [getFavoritedVideos, getVideoUuidsFromProps],
  (favoritedVideos, uuids) => uuids.length > 0 && uuids.every(uuid => favoritedVideos[uuid]),
);

export const makeGetVideoRecycleTime = () => createSelector(
  [getRecycledVideos, getVideoUuidFromProps],
  (recycled, uuid) => recycled[uuid],
);

export const makeGetRecycledVideoDeleteTime = () => createSelector(
  [makeGetVideoRecycleTime(), getRecycleBinDurationDays],
  (recycleTime, durationDays) => {
    if (!recycleTime) {
      return undefined;
    }
    return addDays(recycleTime, durationDays);
  },
);

export const makeGetIsVideoRecycled = () => createSelector(
  [makeGetVideoRecycleTime()],
  (recycleTime) => !!recycleTime,
);

export const getFoldersAsArray = createSelector(
  [getFolders],
  (folders) => Object.values(folders).filter(isExistent),
);

export const makeGetFoldersByUuids = () => createSelector(
  [getFolders, getFolderUuidsFromProps],
  (folders, folderUuids) => folderUuids.map(uuid => folders[uuid]).filter(isExistent),
);

export const getPartitionedFoldersAsArray = createSelector(
  [getFoldersAsArray, getRecycledFolders],
  (folders, recycled) => partition(folders, folder => !recycled[folder.uuid]),
);

export const makeGetItemParent = () => createSelector(
  [getParentDict, getItemUuidFromProps],
  (dict, uuid) => dict[uuid],
);

export const getFolderChildren = createSelector(
  [getParentDict],
  (dict) => {
    const table: Dictionary<Set<UUID>> = {};
    Object.entries(dict).forEach(([child, parent = '']) => {
      if (!table[parent]) {
        table[parent] = new Set();
      }
      table[parent].add(child);
    });
    return table as Partial<Dictionary<Set<UUID>>>;
  },
);

export const makeGetFolderChildren = () => createSelector(
  [getFolderChildren, getFolderUuidFromProps],
  (table, uuid) => uuid ? table[uuid] : new Set(),
);

export const makeGetFolderTree = () => createSelector(
  [getFolders, getParentDict, getFolderUuidFromProps],
  (folders, dict, folderUuid) => {
    if (folderUuid === undefined) {
      return [];
    }
    const path: UUID[] = [folderUuid];
    let current = folderUuid;
    while (dict[current]) {
      current = dict[current]!;
      path.unshift(current);
    }
    return path.map(uuid => folders[uuid]).filter(isExistent);
  },
);

export const makeGetFolderRecycleTime = () => createSelector(
  [getRecycledFolders, getFolderUuidFromProps],
  (recycled, uuid) => uuid ? recycled[uuid] : undefined,
);

export const makeGetIsFolderRecycled = () => createSelector(
  [makeGetFolderRecycleTime()],
  (recycleTime) => !!recycleTime,
);

export const makeGetFolderVideos = () => createSelector(
  [getParentDict, getVideosAsArray, getFolderUuidFromProps],
  (parentDict, videos, folderUuid) => videos.filter(video => parentDict[video.uuid] === folderUuid),
);

export const makeGetFolderFolders = () => createSelector(
  [getParentDict, getFoldersAsArray, getFolderUuidFromProps],
  (parentDict, folders, folderUuid) => folders.filter(folder => parentDict[folder.uuid] === folderUuid),
);

export const makeGetFolderVideosWithRecycleState = () => createSelector(
  [getParentDict, getVideosAsArray, getFolderUuidFromProps, getRecycledVideos, getRecycledFromProps],
  (parentDict, videos, folderUuid, recycledVideos, recycled) => videos.filter(video => !recycled === !recycledVideos[video.uuid] && parentDict[video.uuid] === folderUuid),
);

export const getRecycledFolderRoots = createSelector(
  [getParentDict, getFoldersAsArray, getRecycledFolders],
  (parentDict, folders, recycledFolders) => folders.filter(folder => {
    if (!recycledFolders[folder.uuid]) {
      return false;
    }
    const parentUuid = parentDict[folder.uuid];
    return parentUuid === undefined || !recycledFolders[parentUuid];
  }),
);

export const makeGetFolderFoldersWithRecycleState = () => createSelector(
  [getParentDict, getFoldersAsArray, getFolderUuidFromProps, getRecycledFolders, getRecycledFromProps, getRecycledFolderRoots],
  (parentDict, folders, folderUuid, recycledFolders, recycled, recycledRoots) => {
    if (recycled) {
      return recycledRoots;
    }
    return folders.filter(folder => !recycled === !recycledFolders[folder.uuid] && parentDict[folder.uuid] === folderUuid);
  },
);

const makeIsChild = (parentDict: Partial<Dictionary<UUID>>, folderUuid?: UUID) => (item: Video | VideoLibraryFolder) => {
  let current: UUID | undefined = item.uuid;
  while (current) {
    current = parentDict[current];
    if (current === folderUuid) {
      return true;
    }
  }
  return false;
};

/**
 * Use this in sagas when deleting folders to determine which folders are children and need to be deleted.
 * @returns nested folders that match recycled state
 */
export const makeGetFolderNestedFoldersWithRecycleState = () => createSelector(
  [getParentDict, getFoldersAsArray, getFolderUuidFromProps, getRecycledFolders, getRecycledFromProps],
  (parentDict, folders, folderUuid, recycledFolders, recycled) => {
    const isChild = makeIsChild(parentDict, folderUuid);
    return folders.filter(folder => !recycled === !recycledFolders[folder.uuid] && isChild(folder));
  },
);

/**
 * Use this in sagas when deleting folders to determine which videos are children and need to be deleted.
 * @returns nested videos that match recycled state
 */
export const makeGetFolderNestedVideosWithRecycleState = () => createSelector(
  [getParentDict, getVideosAsArray, getFolderUuidFromProps, getRecycledVideos, getRecycledFromProps],
  (parentDict, videos, folderUuid, recycledVideos, recycled) => {
    const isChild = makeIsChild(parentDict, folderUuid);
    return videos.filter(video => !recycled === !recycledVideos[video.uuid] && isChild(video));
  },
);

export const makeGetProcessingVideosCombinedProgressAsPercentageByParentVideoUuid = () => createSelector(
  [getVideosAsArray, getVideoUuidFromProps],
  (videos, parentUuid) => {
    let numberOfProcessingVideos = 0;
    const progressSum = videos.reduce((progress, video) => {
      if (checkIsAnyMaxProgressProcessingVideo(video) && video.parentVideoUuid === parentUuid) {
        progress += video.progress;
        numberOfProcessingVideos++;
      }
      return progress;
    }, 0);

    return numberOfProcessingVideos === 0 ? undefined : progressSum / numberOfProcessingVideos * 100;
  },
);

/**
 * Find first `ProcessingMergeVideo`. There should only ever be one since we can't export another video while one is in progress.
 * Unless things change, the returned ProcessingMergedVideo has the progress for the current video merge.
 */
export const getProcessingMergeVideoProgressAsPercentage = createSelector(
  [getVideosAsArray],
  (videos) => {
    const processingVideo = videos.find(checkIsProcessingMergeVideo);
    return (processingVideo?.progress ?? 0) * 100;
  },
);
