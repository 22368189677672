import { CLASS_IDS, TeamfightTacticsGameEvent } from '@insights-gaming/game-events';
import { format } from 'date-fns';

import { AnyMaxProgressProcessingVideo, isTFTVideo, ProcessingCreateClipVideo, ProcessingCreateMontageVideo, ProcessingMergeVideo, Video } from '@/features/video-library/video-library-slice';
import { isTFTQueueId, isTruthy } from '@/utils/guard';
import { convertFilePathToOverwolfVideoUrl } from '@/utils/overwolf';

function getVideoFilePath(video: Video): string {
  switch (video.type) {
    // case 'InstantReplay':
    //   return video.result.file;
    default:
      return video.result.file_path;
  }
}
function formatVideoName(video: Video, gameTitleOrCharacter: string, streamStartTime: number, matchNumber: number) {
  return [
    gameTitleOrCharacter,
    `Match ${matchNumber + 1}`,
    format(streamStartTime, 'h:mmaaa'),
  ].filter(isTruthy).join(' - ');
}

export const makeGameClassIdLike = (gameClassId?: number, queueId?: number) => {
  return queueId && isTFTQueueId(queueId) && gameClassId === CLASS_IDS.LEAGUE_TFT ? TeamfightTacticsGameEvent.PSEUDO_CLASS_ID : gameClassId;
};

const getGameClassIdLike = (v: Video) => {
  return isTFTVideo(v) ? TeamfightTacticsGameEvent.PSEUDO_CLASS_ID : v.gameClassId || 0;
};


export const VideoHelper = {
  getVideoFilePath,
  formatVideoName,
  getGameClassIdLike,
};

function makeCommonMaxProgressProcessingVideoProps(uuid: UUID, created: number, filePath: string, maxProgress: number = 0, userProvidedName?: string,  parentVideoUuid?: UUID): Omit<AnyMaxProgressProcessingVideo, 'type'> {
  return {
    uuid,
    parentVideoUuid,
    created,
    size: 0,
    progress: 0,
    maxProgress,
    userProvidedName,
    result: {
      duration: 0,
      file_path: filePath,
      url: convertFilePathToOverwolfVideoUrl(filePath),
    },
  };
}

export function makeProcessingCreateClipVideo(...args: Parameters<typeof makeCommonMaxProgressProcessingVideoProps>): ProcessingCreateClipVideo {
  return {
    type: 'ProcessingCreateClipVideo',
    ...makeCommonMaxProgressProcessingVideoProps(...args),
  };
}

export function makeProcessingCreateMontageVideo(...args: Parameters<typeof makeCommonMaxProgressProcessingVideoProps>): ProcessingCreateMontageVideo {
  return {
    type: 'ProcessingCreateMontageVideo',
    ...makeCommonMaxProgressProcessingVideoProps(...args),
  };
}

export function makeProcessingMergeVideo(...args: Parameters<typeof makeCommonMaxProgressProcessingVideoProps>): ProcessingMergeVideo {
  return {
    type: 'ProcessingMergeVideo',
    ...makeCommonMaxProgressProcessingVideoProps(...args),
  };
}
