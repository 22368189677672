import { OperationVariables, TypedDocumentNode } from '@apollo/client';
import { AutoFetcherParams, BackwardFetchOptions, BidirectionalAsyncActionCreators, BidirectionalIDRecord, BidirectionalSelector, CreateBidirectionalAutoFetcherOptions, createDefaultRecord, FetchStatus, fetchUpdate, ForwardFetchOptions, QueryCursorOptions } from '@insights-gaming/redux-utils';
import { ActionReducerMapBuilder, createSelector, ParametricSelector } from '@reduxjs/toolkit';
import { Draft } from 'immer';
import update from 'immutability-helper';
import { get } from 'lodash';
import { DependencyList, useCallback, useRef, useState } from 'react';
import { SagaIterator } from 'redux-saga';
import { call, CallEffect, fork, ForkEffect, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';
import { TypedPathWrapper } from 'typed-path';
import { Action, AsyncActionCreators, Failure, Success } from 'typescript-fsa';

import { PageInfoFragment } from '@/apollo/fragments/__generated__/PageInfoFragment';
import { RootReducer } from '@/app/rootReducer.app';
import { isExistent } from '@/utils/guard';
import { mutateRefresh, queryRefreshWithRetry } from '@/utils/insightsgg/graphql';
import { takeEveryPromise } from '@/utils/promisify-saga';

import { createDefaultFetchStatus, getFromDictWithKey, getFromDictWithKeys } from './..';

export interface BidirectionalState<T> {
  records: Partial<Dictionary<BidirectionalIDRecord>>;
  itemDict: Partial<Dictionary<T>>;
  statusDict: Partial<Dictionary<FetchStatus>>;
}

interface BsOptions<_TState, TParams, TResult, _TError, TItem> {
  accessor: TypedPathWrapper<BidirectionalState<TItem>, DefaultHandlers>;
  itemKey: (item: TItem) => string;
  recordsKey: (params: TParams) => string;
  resultTransform: (result: TResult) => [TItem[], PageInfoFragment] | undefined;
  updateDict?: boolean;
}

export function createDefaultBidirectionalState<T>(): BidirectionalState<T> {
  return {
    records: {},
    itemDict: {},
    statusDict: {},
  };
}

export function buildBs<TState, TParams extends QueryCursorOptions, TResult, TError extends Error, TItem>(
  builder: ActionReducerMapBuilder<TState>,
  bidirectionalAC: BidirectionalAsyncActionCreators<TParams, TResult, TError>,
  options: BsOptions<TState, TParams, TResult, TError, TItem>,
) {
  build(builder, bidirectionalAC, 'forward', options);
  build(builder, bidirectionalAC, 'backward', options);
}

function build<TState, TParams extends QueryCursorOptions, TResult, TError extends Error, TItem>(
  builder: ActionReducerMapBuilder<TState>,
  bidirectionalAC: BidirectionalAsyncActionCreators<TParams, TResult, TError>,
  direction: 'forward' | 'backward',
  {
    accessor,
    itemKey,
    recordsKey,
    resultTransform,
    updateDict = true,
  }: BsOptions<TState, TParams, TResult, TError, TItem>,
) {
  builder.addCase(bidirectionalAC[direction].started, (state, action) => {
    const bs: BidirectionalState<TItem> = get(state, accessor.$path);
    bs.records = update(bs.records || createDefaultRecord(), {
      [recordsKey(action.payload as any)]: record => fetchUpdate(record || createDefaultRecord(), { [`$${direction}Started`]: true }),
    });
  });
  builder.addCase(bidirectionalAC[direction].done, (state, action) => {
    const { payload: { params, result } } = action;
    const bs: BidirectionalState<TItem> = get(state, accessor.$path);
    const transformed = resultTransform(result!);
    if (!transformed) {
      return;
    }
    const [items, pageInfo] = transformed;
    bs.records = update(bs.records || createDefaultRecord(), {
      [recordsKey(params as any)]: record => fetchUpdate(record || createDefaultRecord(), { [`$${direction}Done`]: [items.map(itemKey), pageInfo] }),
    });
    if (updateDict) {
      const dict = Object.fromEntries(items.map(item => [itemKey(item), item]));
      bs.itemDict = update(bs.itemDict, { $merge: dict });
    }
  });
  builder.addCase(bidirectionalAC[direction].failed, (state, action) => {
    const { payload: { params, error } } = action;
    const bs: BidirectionalState<TItem> = get(state, accessor.$path);
    bs.records = update(bs.records || createDefaultRecord(), {
      [recordsKey(params as any)]: record => fetchUpdate(record || createDefaultRecord(), { [`$${direction}Failed`]: [error] }),
    });
  });
}

interface AsOptions<_TState, TParams, TResult, _TError, TItem> {
  accessor: TypedPathWrapper<BidirectionalState<TItem>, DefaultHandlers>;
  paramsTransform: (params: TParams) => string[];
  resultTransform: (result: TResult) => (TItem | null)[];
  allowEmptyResult?: boolean;
}

export function asyncStarted<TState, TParams, TResult, TError extends Error, TItem>(
  builder: ActionReducerMapBuilder<TState>,
  asyncAC: AsyncActionCreators<TParams, TResult, TError>,
  options: Pick<AsOptions<TState, TParams, TResult, TError, TItem>, 'accessor' | 'paramsTransform'>,
) {
  const { accessor, paramsTransform } = options;

  builder.addCase(asyncAC.started, (state, action) => {
    const bs: BidirectionalState<TItem> = get(state, accessor.$path);
    const keys = paramsTransform(action.payload);
    keys.forEach(key => {
      bs.statusDict[key] = fetchUpdate(bs.statusDict[key] || createDefaultFetchStatus(), { $fetchStarted: true });
    });
  });
}

export function asyncFailed<TState, TParams, TResult, TError extends Error, TItem>(
  builder: ActionReducerMapBuilder<TState>,
  asyncAC: AsyncActionCreators<TParams, TResult, TError>,
  options: Pick<AsOptions<TState, TParams, TResult, TError, TItem>, 'accessor' | 'paramsTransform'>,
) {
  const { accessor, paramsTransform } = options;

  builder.addCase(asyncAC.failed, (state, action) => {
    const bs: BidirectionalState<TItem> = get(state, accessor.$path);
    const keys = paramsTransform(action.payload.params!);
    keys.forEach(key => {
      bs.statusDict[key] = fetchUpdate(bs.statusDict[key] || createDefaultFetchStatus(), { $fetchFailed: [action.payload.error] });
    });
  });
}

export function buildAsync<TState, TParams, TResult, TError extends Error, TItem>(
  builder: ActionReducerMapBuilder<TState>,
  asyncAC: AsyncActionCreators<TParams, TResult, TError>,
  options: AsOptions<TState, TParams, TResult, TError, TItem>,
) {
  asyncStarted(builder, asyncAC, options);
  asyncFailed(builder, asyncAC, options);

  const { accessor, paramsTransform, resultTransform } = options;

  builder.addCase(asyncAC.done, (state, action) => {
    const bs: BidirectionalState<TItem> = get(state, accessor.$path);
    const keys = paramsTransform(action.payload.params!);
    const items = resultTransform(action.payload.result!);
    const len = Math.min(keys.length, items.length);
    for (let i = 0; i < len; i++) {
      const key = keys[i];
      const item = items[i];
      if (item) {
        bs.itemDict[key] = item;
        bs.statusDict[key] = fetchUpdate(bs.statusDict[key] || createDefaultFetchStatus(), { $fetchDone: true });
      } else {
        bs.statusDict[key] = fetchUpdate(bs.statusDict[key] || createDefaultFetchStatus(), { $fetchFailed: [new Error('not found')] });
      }
    }
  });
}

export function updateAsync<TState, TParams, TResult, TError extends Error, TItem>(
  builder: ActionReducerMapBuilder<TState>,
  asyncAC: AsyncActionCreators<TParams, TResult, TError>,
  options: AsOptions<TState, TParams, TResult, TError, TItem> & {
    started?: (state: Draft<TState>, action: Action<TParams>) => void;
    failed?: (state: Draft<TState>, action: Action<Failure<TParams, TError>>) => void;
    done?: (state: Draft<TState>, action: Action<Success<TParams, TResult>>) => void;
  },
) {
  const { accessor, paramsTransform, resultTransform, started, done, failed } = options;

  if (started) {
    builder.addCase(asyncAC.started, started);
  }

  if (failed) {
    builder.addCase(asyncAC.failed, failed);
  }

  builder.addCase(asyncAC.done, (state, action) => {
    if (done) {
      done(state, action);
    }
    const bs: BidirectionalState<TItem> = get(state, accessor.$path);
    const keys = paramsTransform(action.payload.params!);
    const items = resultTransform(action.payload.result!);
    const len = Math.min(keys.length, items.length);
    for (let i = 0; i < len; i++) {
      const key = keys[i];
      const item = items[i];
      if (item) {
        bs.itemDict[key] = item;
      } else if (options.allowEmptyResult) {
        delete bs.itemDict[key];
      }
    }
  });
}

interface DeleteOptions<_TState, TParams, TResult, _TError, TItem> {
  accessor: TypedPathWrapper<BidirectionalState<TItem>, DefaultHandlers>;
  additionalAccessors?: TypedPathWrapper<BidirectionalState<unknown>, DefaultHandlers>[];
  paramsTransform: (params: TParams) => string[];
  resultTransform: (result: TResult) => string[];
}

export function deleteAsync<TState, TParams, TResult, TError extends Error, TItem>(
  builder: ActionReducerMapBuilder<TState>,
  asyncAC: AsyncActionCreators<TParams, TResult, TError>,
  options: DeleteOptions<TState, TParams, TResult, TError, TItem>,
) {
  const { accessor, additionalAccessors = [], paramsTransform, resultTransform } = options;
  builder.addCase(asyncAC.done, (state, action) => {
    const accessors = [accessor, ...additionalAccessors];
    for (const accessor of accessors) {
      const bs: BidirectionalState<TItem> = get(state, accessor.$path);
      const keys = paramsTransform(action.payload.params!);
      const items = resultTransform(action.payload.result!);
      const len = Math.min(keys.length, items.length);
      for (let i = 0; i < len; i++) {
        const key = keys[i];
        const deleted = items[i];
        if (deleted) {
          delete bs.itemDict[key];
          delete bs.statusDict[key];
        }
      }
    }
  });
}

export function watchBs<TParams extends QueryCursorOptions, TResult, TError extends Error>(
  bidirectionalAC: BidirectionalAsyncActionCreators<TParams, TResult, TError>,
  worker: (action: Action<ForwardFetchOptions<TParams> | BackwardFetchOptions<TParams>>) => Promise<TResult> | SagaIterator<TResult>,
): ForkEffect {
  return fork(function* () {
    yield takeEveryPromise<ForwardFetchOptions<TParams>, TResult, TError>(bidirectionalAC.forward, worker);
    yield takeEveryPromise<BackwardFetchOptions<TParams>, TResult, TError>(bidirectionalAC.backward, worker);
  });
}

export function watchBsQuery<TParams extends QueryCursorOptions, TResult, TError extends Error>(
  bidirectionalAC: BidirectionalAsyncActionCreators<TParams, TResult, TError>,
  query: TypedDocumentNode<TResult, TParams>,
): ForkEffect {
  return watchBs(bidirectionalAC, function* (action): SagaIterator {
    return yield queryRefreshWithRetry<TResult, ForwardFetchOptions<TParams> | BackwardFetchOptions<TParams>>({
      variables: action.payload,
      query,
    });
  });
}

export function watchQuery<TParams extends OperationVariables, TResult, TError extends Error>(
  asyncAC: AsyncActionCreators<TParams, TResult, TError>,
  query: TypedDocumentNode<TResult, TParams>,
): ForkEffect {
  return takeEveryPromise(asyncAC, function* (action): SagaIterator {
    return yield queryRefreshWithRetry<TResult, TParams>({
      variables: action.payload,
      query,
    });
  });
}

interface AsyncWorkers<TParams, TResult, TError extends Error> {
  started?: (action: Action<TParams>) => StrictEffect | Generator<StrictEffect>;
  done?: (action: Action<Success<TParams, TResult>>) => StrictEffect | Generator<StrictEffect>;
  failed?: (action: Action<Failure<TParams, TError>>) => StrictEffect | Generator<StrictEffect>;
}

export function watchAsyncCases<TParams, TResult, TError extends Error>(
  asyncAC: AsyncActionCreators<TParams, TResult, TError>,
  {
    started,
    done,
    failed,
  }: AsyncWorkers<TParams, TResult, TError>,
) {
  return fork(function* () {
    if (started) {
      yield takeEvery(asyncAC.started, started);
    }
    if (done) {
      yield takeEvery(asyncAC.done, done);
    }
    if (failed) {
      yield takeEvery(asyncAC.failed, failed);
    }
  });
}

export function watchMutation<TParams, TResult, TError extends Error>(
  asyncAC: AsyncActionCreators<TParams, TResult, TError>,
  mutation: TypedDocumentNode<TResult, { input: TParams }>,
  additionalWorkers?: AsyncWorkers<TParams, TResult, TError>,
): ForkEffect {
  return fork(function* () {
    if (additionalWorkers) {
      yield watchAsyncCases(asyncAC, additionalWorkers);
    }
    yield takeEveryPromise(asyncAC, function* (action): SagaIterator {
      return yield mutateRefresh<TResult, { input: TParams }>({
        variables: { input: action.payload },
        mutation,
      });
    });
  });
}

type PropSelector<TProps, R> = (_: RootReducer, props: TProps) => R;

function createPropSelector<Key extends string, R>(k: Key): PropSelector<Record<Key, R>, R> {
  return (_: RootReducer, props: Record<Key, R>) => get(props, k);
}

interface SelectBsOptions<K1 extends string, K2 extends string, K3 extends string> {
  groupId: K1;
  itemId: K2;
  itemIds: K3;
}

export function selectBs<TItem, K1 extends string, K2 extends string, K3 extends string>(
  selector: (state: RootReducer) => BidirectionalState<TItem>,
  {
    groupId,
    itemId,
    itemIds,
  }: SelectBsOptions<K1, K2, K3>,
) {
  const getGroupIdFromProps = createPropSelector<K1, string>(groupId);
  const getItemIdFromProps = createPropSelector<K2, string>(itemId);
  const getItemIdsFromProps = createPropSelector<K3, string[]>(itemIds);

  const getRecords = createSelector(
    [selector],
    bs => bs.records,
  );

  const getItemDict = createSelector(
    [selector],
    bs => bs.itemDict,
  );

  const getStatusDict = createSelector(
    [selector],
    bs => bs.statusDict,
  );

  const makeGetRecordsByGroupId = () => createSelector(
    [getRecords, getGroupIdFromProps],
    getFromDictWithKey,
  );

  const makeGetItemsByGroupId = () => createSelector(
    [makeGetRecordsByGroupId(), getItemDict],
    (record, dict) => {
      if (!record) {
        return [];
      }
      return record.ids.map(id => dict[id]).filter(isExistent);
    },
  );

  const makeGetStatusByItemId = () => createSelector(
    [getStatusDict, getItemIdFromProps],
    getFromDictWithKey,
  );

  const makeGetItemByItemId = () => createSelector(
    [getItemDict, getItemIdFromProps],
    getFromDictWithKey,
  );

  const makeGetItemsByItemIds = () => createSelector(
    [getItemDict, getItemIdsFromProps],
    getFromDictWithKeys,
  );

  const makeGetEntriesByGroupId = () => createSelector(
    [getRecords, getItemDict, getStatusDict, getGroupIdFromProps],
    (records, itemDict, statusDict, groupId) => {
      const record = records[groupId];
      if (!record) {
        return [];
      }
      return record.ids.map(id => [itemDict[id], statusDict[id]]).filter(([item]) => isExistent(item));
    },
  );

  const makeGetEntriesByItemIds = () => createSelector(
    [getItemDict, getStatusDict, getItemIdsFromProps],
    (itemDict, statusDict, itemIds) => itemIds.map(id => [itemDict[id], statusDict[id]]),
  );

  return {
    makeGetRecordsByGroupId,
    makeGetItemsByGroupId,
    makeGetStatusByItemId,
    makeGetItemByItemId,
    makeGetItemsByItemIds,
    makeGetEntriesByGroupId,
    makeGetEntriesByItemIds,
  };
}

export type UseAsyncCallback<P extends any[], R> = [
  (...args: P) => Promise<R | undefined>,
  boolean,
  Error | undefined,
];

export function useAsyncCallback<P extends any[], R>(
  callback: (...args: P) => Promise<R>,
  deps: DependencyList,
): UseAsyncCallback<P, R> {
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  const [error, setError] = useState<Error>();
  const cb = useCallback(async (...args: P) => {
    if (loadingRef.current) {
      return undefined;
    }
    setLoading(true);
    try {
      return await callback(...args);
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps); // useAsyncCallback deps are linted separately
  return [cb, loading, error];
}

function createParametricSelector<S, P = {}, R = any>(
  factory: () => ParametricSelector<S, P, R>,
  props: P,
  ...args: any[]
) {
  const _selector = factory();
  const selector = (state: S) => _selector(state, props, ...args);
  return selector;
}

function selectBsStatus<S>(selector: BidirectionalSelector<S>): CallEffect<BidirectionalIDRecord> {
  return call(function* (): SagaIterator {
    const defaultRecord: BidirectionalIDRecord = yield call(createDefaultRecord);
    const status: BidirectionalIDRecord = yield select(selector);
    const { forward, backward } = status || defaultRecord;
    return { forward, backward } as const;
  });
}

export function bsEffects<T extends QueryCursorOptions, S>(
  {
    actionCreators,
    createFetchId,
    pageSize,
    selectorFactory,
  }: CreateBidirectionalAutoFetcherOptions<T, S>,
) {
  function forwardFetch(params: AutoFetcherParams<T>, limit: number, cursor?: string) {
    return call(function* () {
      yield put(actionCreators.forward.started({
        ...params,
        after: cursor,
        limit,
      } as ForwardFetchOptions<T>));
    });
  }

  function backwardFetch(params: AutoFetcherParams<T>, limit: number, cursor?: string) {
    return call(function* () {
      yield put(actionCreators.backward.started({
        ...params,
        before: cursor,
        limit,
      } as BackwardFetchOptions<T>));
    });
  }

  function forwardFetchMore(params: AutoFetcherParams<T>, limit: number) {
    return call(function* () {
      const selector = createParametricSelector(selectorFactory, params);
      const { forward }: BidirectionalIDRecord = yield selectBsStatus(selector);
      if (forward) {
        yield forwardFetch(params, limit, forward.cursor);
      }
    });
  }

  function backwardFetchMore(params: AutoFetcherParams<T>, limit: number) {
    return call(function* () {
      const selector = createParametricSelector(selectorFactory, params);
      const { backward }: BidirectionalIDRecord = yield selectBsStatus(selector);
      if (backward) {
        yield backwardFetch(params, limit, backward.cursor);
      }
    });
  }

  return {
    forwardFetcher: { fetch: forwardFetch, fetchMore: forwardFetchMore },
    backwardFetcher: { fetch: backwardFetch, fetchMore: backwardFetchMore },
  };
}
