import { AsyncButton, EnhancedDialogTitle } from '@insights-gaming/material-components';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, TextField } from '@mui/material';
import { ChangeEvent, FormEvent, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RenameSource, VideoRenamedMeta } from '@/features/video-library/video-library-slice';

interface RenameDialogOwnProps {
  renameSource?: RenameSource;
  className?: string;
  initialName: string;
  title: string;
  confirmText: string;
  onClose?: VoidFunction;
  renaming?: boolean;
  onRename?: (newName: string, meta?: VideoRenamedMeta) => Promise<void> | void;
}

type RenameDialogProps = RenameDialogOwnProps & Pick<DialogProps, 'open' | 'fullWidth'>;

function RenameDialog(props: RenameDialogProps) {
  const { open, onClose, title, fullWidth } = props;
  return (
    <Dialog open={open} fullWidth={fullWidth} onClose={onClose}>
      <EnhancedDialogTitle onClose={onClose}>
        {title}
      </EnhancedDialogTitle>
      <RenameDialogContent {...props} />
    </Dialog>
  );
}

function RenameDialogContent(props: RenameDialogProps) {
  const { confirmText, initialName, onClose, onRename, renameSource, renaming } = props;

  const { t } = useTranslation(['common']);

  const [newName, setNewName] = useState(initialName);
  const trimmed = useMemo(() => newName.trim(), [newName]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { currentTarget: { value } } = e;
    setNewName(value);
  }, []);

  const handleSubmit = useCallback(async (e: FormEvent) => {
    e.preventDefault();
    await onRename?.(trimmed, { renameSource });
    onClose?.();
  }, [onClose, onRename, renameSource, trimmed]);

  const noName = !trimmed;

  const additionalProps = useMemo(
    () => noName ? ({ error: true/*, helperText: 'required' */ }) : {},
    [noName],
  );

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <TextField name='videoName' value={newName} fullWidth={true} autoFocus={true} onChange={handleChange} {...additionalProps} />
      </DialogContent>
      <DialogActions>
        <Button type='button' variant='outlined' onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <AsyncButton type='submit' variant='contained' color='primary' disabled={noName || renaming} loading={renaming} onClick={handleSubmit}>
          {confirmText}
        </AsyncButton>
      </DialogActions>
    </form>
  );
}

export default memo(RenameDialog);
