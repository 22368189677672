import { createBackoffFunction } from '@insights-gaming/backoff';
import { SagaIterator } from '@redux-saga/types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Task } from 'redux-saga';
import { call, CallEffect, delay, fork, ForkEffect, join, put, race, RaceEffect, take, TakeEffect, takeEvery } from 'redux-saga/effects';
import actionCreatorFactory, { Action, AsyncActionCreators, Failure, Meta, Success } from 'typescript-fsa';

import { uuidv4 } from '@/app/uuid';

import { UseAsyncCallback, useAsyncCallback } from './redux';

type AsyncAC = AsyncActionCreators<any, any, Error>;

interface PromisifyParams {
  asyncAC: AsyncAC;
  params: any;
}

const promisify = actionCreatorFactory()<PromisifyParams>('PROMISIFY');

const resolveSymbol = 'resolve';
const rejectSymbol = 'reject';
const uuidSymbol = 'uuid';

interface MetaExtension<TResult = any, TError = any> {
  [resolveSymbol]: (result: TResult) => void;
  [rejectSymbol]: (error: TError) => void;
}

// export function* promisifyAsyncAction(
//   asyncAC: AsyncAC,
//   params: any,
//   meta: Meta = {},
// ): SagaIterator<Promise<any>> {
//   let action: Action<PromisifyParams>;
//   const p = new Promise((resolve, reject) => {
//     action = promisify({ asyncAC, params }, { ...meta, [resolveSymbol]: resolve, [rejectSymbol]: reject });
//   });
//   yield put(action!);
//   return p;
// }

export default function* promisifySaga() {
  yield takeEvery(promisify, function* ({
    payload: { asyncAC, params },
    meta: { [resolveSymbol]: resolve, [rejectSymbol]: reject, ...meta },
  }: Action<PromisifyParams> & { meta: NonNullable<Meta> & MetaExtension }) {
    const uuid: UUID = yield call(uuidv4);

    yield put(asyncAC.started(params, { [uuidSymbol]: uuid, ...meta }));

    const { done, failed } = yield raceAsyncAC(asyncAC, uuid);

    if (done) {
      resolve?.(done.payload.result);
    } else if (failed) {
      reject?.(failed.payload.error);
    }
  });
}

function raceAsyncAC<AC extends AsyncAC>(asyncAC: AC, uuid: UUID): RaceEffect<TakeEffect> {
  return race({
    done: take((action: any) => asyncAC.done.match(action) && action.meta?.[uuidSymbol] === uuid),
    failed: take((action: any) => asyncAC.failed.match(action) && action.meta?.[uuidSymbol] === uuid),
  });
}

function* racePut<AC extends AsyncAC>(
  asyncAC: AC,
  params: ParamsFromAsyncActionCreator<AC>,
  meta?: any,
): SagaIterator {
  const uuid: UUID = yield call(uuidv4);
  yield put(asyncAC.started(params, { [uuidSymbol]: uuid, ...meta }));
  return yield raceAsyncAC(asyncAC, uuid);
}

export function promisifyPut<AC extends AsyncAC>(
  asyncAC: AC,
  params: ParamsFromAsyncActionCreator<AC>,
  meta?: any,
): CallEffect {
  return call(racePut, asyncAC, params, meta);
}

export type RaceAsync<AC> = AC extends AsyncActionCreators<infer P, infer R, infer E> ? RaceAsyncResult<P, R, E> : never;

interface RaceAsyncResult<P, R, E> {
  done?: Action<Success<P, R>>;
  failed?: Action<Failure<P, E>>;
};

export function promisePut<P, R, E>(
  asyncAC: AsyncActionCreators<P, R, E>,
  params: P,
  meta?: any,
): CallEffect {
  return call(function* (): SagaIterator {
    const uuid: UUID = yield call(uuidv4);
    yield put(asyncAC.started(params, { [uuidSymbol]: uuid, ...meta }));
    return yield race({
      done: take((action: any) => asyncAC.done.match(action) && action.meta?.[uuidSymbol] === uuid),
      failed: take((action: any) => asyncAC.failed.match(action) && action.meta?.[uuidSymbol] === uuid),
    });
  });
}

function combineMeta<AC extends AsyncAC>(
  asyncAC: AC,
  params: ParamsFromAsyncActionCreator<AC>,
  resolve: (value: ResultFromAsyncActionCreator<AC> | PromiseLike<ResultFromAsyncActionCreator<AC>>) => void,
  reject: (reason?: any) => void,
  meta?: any,
) {
  return promisify({ asyncAC, params }, { ...meta, [resolveSymbol]: resolve, [rejectSymbol]: reject });
}

export function usePromiseSagaDispatch() {
  const dispatch = useDispatch();
  return useCallback(<AC extends AsyncAC>(
    asyncAC: AC,
    params: ParamsFromAsyncActionCreator<AC>,
    meta?: any,
  ) => {
    return new Promise<ResultFromAsyncActionCreator<AC>>((resolve, reject) => {
      const action = combineMeta(asyncAC, params, resolve, reject, meta);
      dispatch(action);
    });
  }, [dispatch]);
}

export function usePromiseSagaCallback<AC extends AsyncAC, M extends Meta>(asyncAC: AC): UseAsyncCallback<[ParamsFromAsyncActionCreator<AC>, M | void], ResultFromAsyncActionCreator<AC>>;
export function usePromiseSagaCallback<AC extends AsyncAC>(asyncAC: AC): UseAsyncCallback<[ParamsFromAsyncActionCreator<AC>], ResultFromAsyncActionCreator<AC>>;
export function usePromiseSagaCallback<AC extends AsyncAC>(asyncAC: AC, params: ParamsFromAsyncActionCreator<AC>): UseAsyncCallback<[], ResultFromAsyncActionCreator<AC>>;
export function usePromiseSagaCallback<AC extends AsyncAC, M extends Meta>(asyncAC: AC, params?: ParamsFromAsyncActionCreator<AC>): UseAsyncCallback<[], ResultFromAsyncActionCreator<AC>> | UseAsyncCallback<[ParamsFromAsyncActionCreator<AC>], ResultFromAsyncActionCreator<AC>> {
  const promiseSagaDispatch = usePromiseSagaDispatch();
  return useAsyncCallback((p: ParamsFromAsyncActionCreator<AC>, meta?: M) => {
    return promiseSagaDispatch(asyncAC, params ?? p, meta);
  }, [asyncAC, params, promiseSagaDispatch]);
}

export function takeEveryPromise<P, R, E>(
  asyncAC: AsyncActionCreators<P, R, E>,
  worker: (action: Action<P>) => Promise<R> | SagaIterator<R>,
): ForkEffect<never> {
  return takeEvery(asyncAC.started, function* (action) {
    const { payload: params, meta } = action;
    try {
      const result: R = yield call(worker, action);
      yield put(asyncAC.done({ params, result }, meta));
    } catch (error) {
      yield put(asyncAC.failed({ params, error }, meta));
    }
  });
}

export function takeLeadingPromise<P, R, E>(
  asyncAC: AsyncActionCreators<P, R, E>,
  worker: (action: Action<P>) => Promise<R> | SagaIterator<R>,
  makeTaskId?: (action: Action<P>) => string,
): ForkEffect<never> {
  return fork(function* () {
    let taskDict: Dictionary<Task> = {};
    type ReturnAction = ActionFromActionCreator<typeof asyncAC.done | typeof asyncAC.failed>;
    function clearTask(id: string) {
      delete taskDict[id];
    }
    while (true) {
      const action: Action<P> = yield take(asyncAC.started);
      const taskId = makeTaskId ? makeTaskId(action) : 'default';
      const lastTask = taskDict[taskId];
      const { payload: params, meta } = action;
      if (lastTask) {
        const prev = lastTask;
        taskDict[taskId] = yield fork(function* () {
          const action: ReturnAction = yield join(prev);
          const returnAction = { ...action, meta };
          yield put(returnAction);
          yield call(clearTask, taskId);
          return returnAction;
        });
      } else {
        taskDict[taskId] = yield fork(function* () {
          let returnAction: ReturnAction | undefined;
          try {
            const result: R = yield call(worker, action);
            returnAction = asyncAC.done({ params, result }, meta);
          } catch (error) {
            returnAction = asyncAC.failed({ params, error }, meta);
          }
          yield put(returnAction);
          yield call(clearTask, taskId);
          return returnAction;
        });
      }
    }
  });
}

interface RetryOptions {
  maxAttempts?: number;
  backoff?: (i: number) => number;
}

const defaultBackoff = createBackoffFunction({
  jitter: 0,
  max: 1000,
  multiplier: 1000,
});

export function takeEveryPromiseWithRetry<P, R, E>(
  asyncAC: AsyncActionCreators<P, R, E>,
  worker: (action: Action<P>) => Promise<R> | SagaIterator<R>,
  options?: RetryOptions,
): ForkEffect<never> {
  let {
    backoff = defaultBackoff,
    maxAttempts = 1,
  } = options || {};

  if (maxAttempts < 1) {
    maxAttempts = 1;
  }

  return takeEvery(asyncAC.started, function* (action) {
    const { payload: params, meta } = action;

    let err;
    for (let i = 0; i < maxAttempts; i++) {
      try {
        if (i > 0) {
          console.log(`${asyncAC.type} retry attempt:`, i);
          yield delay(backoff(i));
        }
        const result: R = yield call(worker, action);
        yield put(asyncAC.done({ params, result }, meta));
        return;
      } catch (error) {
        err = error;
      }
    }
    yield put(asyncAC.failed({ params, error: err }, meta));
  });
}
