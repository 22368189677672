import { CLASS_IDS, GameClassId, TeamfightTacticsGameEvent } from '@insights-gaming/game-events';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import update from 'immutability-helper';
import { fromPairs } from 'lodash';
import actionCreatorFactory from 'typescript-fsa';

import { TFTQueueId } from '@/app/constants';
import { VideoBookmark } from '@/features/bookmarks/bookmark-slice';
import { VideoComment } from '@/features/comment-panel/comment-slice';
import { VideoSegment } from '@/features/video-clipping/video-clipping-slice';
import { MixpanelEventSource } from '@/mixpanel/common';
import { isTFTQueueId } from '@/utils/guard';
import { humanizeVideoName } from '@/utils/string-manips';

import { CropOption } from './CropOption';

const name = 'video-library';
const actionCreator = actionCreatorFactory(name);
export const loadVideoDatabaseAC = actionCreator.async<void, Dictionary<Video>, Error>('LOAD_VIDEO_DATABASE');
export const loadLibraryMetadataDatabaseAC = actionCreator.async<void, LibraryMetadata, Error>('LOAD_LIBRARY_METADATA_DATABASE');
export const createVideoClipsAC = actionCreator.async<CreateVideoClipParams, (VideoClip | MergedVideo)[], Error>('CREATE_VIDEO_CLIPS');
export const cleanupVideoClipsAC = actionCreator.async<void, void, Error>('CLEANUP_VIDEO_CLIPS');
export const checkVideoMissingAC = actionCreator<UUID>('CHECK_VIDEO_MISSING');
export const deleteOldVideosAC = actionCreator.async<void, void, Error>('DELETE_OLD_VIDEOS');
export const loadVideoRecycleBinDatabaseAC = actionCreator.async<void, Dictionary<number>, Error>('LOAD_VIDEO_RECYCLE_BIN_DATABASE');
export const deleteAllRecycledVideosAC = actionCreator.async<void, void, Error>('DELETE_ALL_RECYCLED_VIDEOS');
export const deleteVideoAC = actionCreator.async<UUID, void, Error>('DELETE_VIDEO');
export const deleteVideosAC = actionCreator.async<UUID[], VideosDeletedPayload, Error>('DELETE_VIDEOS');
export const deleteFolderAC = actionCreator.async<UUID, void, Error>('DELETE_FOLDER');
export const addToDeleteQueue = actionCreator<AddToDeleteQueueParams>('ADD_TO_DELETE_QUEUE');
export const waitForDeleteQueueAC = actionCreator.async<void, void, Error>('WAIT_FOR_DELETE_QUEUE');
export const loadFolderDatabaseAC = actionCreator.async<void, Dictionary<VideoLibraryFolder>, Error>('LOAD_FOLDER_DATABASE');
export const loadFolderRecycleBinDatabaseAC = actionCreator.async<void, Dictionary<number>, Error>('LOAD_FOLDER_RECYCLE_BIN_DATABASE');
export const loadLibraryItemParentDatabaseAC = actionCreator.async<void, Dictionary<UUID>, Error>('LOAD_LIBRARY_ITEM_PARENT_DATABASE');
export const createMontageClipAC = actionCreator.async<Video, MontageClip[], Error>('CREATE_MONTAGE_CLIP');
export const createMontageClipFromQueueAC = actionCreator.async<void, MontageClip[], Error>('CREATE_MONTAGE_CLIP_FROM_QUEUE');
export const checkRecycleBin = actionCreator<void>('CHECK_RECYCLE_BIN');
export const getAvailableFreeSpaceAC = actionCreator.async<string, number, Error>('GET_AVAILABLE_FREE_SPACE');
export const createMergedVideoAC = actionCreator.async<CreateMergedVideoParams, MergedVideo, Error>('CREATE_MERGED_VIDEO');
export const importVideosAC = actionCreator.async<ImportVideosParams, ImportVideosResult, Error>('IMPORT_VIDEOS');
export const exportVideosAC = actionCreator.async<ExportVideosParams, unknown, Error>('EXPORT_VIDEOS');
export const downloadDemoVideoAC = actionCreator.async<DownloadDemoVideoParams, { video: DemoVideo; bookmarks?: Partial<Dictionary<VideoBookmark>>; comments?: Partial<Dictionary<VideoComment>> }, Error>('DOWNLOAD_DEMO_VIDEO');
export const addDemoVideoToLibrary = actionCreator<void>('ADD_DEMO_VIDEO_TO_LIBRARY');

export type MontageSource = MixpanelEventSource<'Video Replay Page'>;

export interface CreateVideoClipParams {
  video: Video;
  segments: VideoSegment[];
}

interface CreateMergedVideoParams {
  userProvidedName?: string;
  videos: Video[];
}

interface AddToDeleteQueueParams {
  paths: string[];
  callback?: (res: overwolf.extensions.io.DeleteResult[]) => void;
  msg?: string;
}

interface ImportVideosParams extends VideoImportOptions {
  paths: string[];
}

export interface ImportVideosResult {
  done: ImportVideo[];
  failed: Array<{
    src: string;
    error: Error;
  }>;
}

type VideoCaptureMode = 'auto' | 'manual' | 'session';

/**
 * Video interface
 * @prop {number} size - in bytes
 * @param {number} result.duration is in ms
 */
export interface _Video {
  uuid: UUID;
  userProvidedName?: string;
  created: number;
  size: number;
  gameClassId?: number;
  captureMode?: VideoCaptureMode;

  result: {
    duration: number;
    file_path: string;
    url: string;
  };
}

interface _Clip extends _Video {
  fullVideoUuid: UUID;
}

/**
 * The duration property from overwolf.streaming.StopStreamingResult is not accurate. It differs slightly from the file's actual duration.
 * Don't use it to do calculations if possible. It will lead to some inaccurate results.
 */
export interface StreamVideo extends _Video {
  type?: never;
  result: overwolf.streaming.StopStreamingResult | overwolf.streaming.StopStreamingEvent;
}

export interface VideoClip extends _Clip {
  type: 'VideoClip';
}

export interface MontageClip extends _Clip {
  type: 'MontageClip';
}

export interface InstantReplay extends _Video {
  type: 'InstantReplay';
}

export interface ImportVideo extends _Video {
  type: 'ImportVideo';
}

export interface ProcessingVideo extends _Video {
  type: 'ProcessingVideo';
  size: 0;
  progress: number;
  result: {
    duration: 0;
    file_path: string;
    url: string;
  };
}

export interface DemoVideo extends Omit<ImportVideo, 'type'> {
  type: 'DemoVideo';
}

export interface ProcessingDemoVideo extends Omit<ProcessingVideo, 'type'> {
  type: 'ProcessingDemoVideo';
}

/**
 * @param {number} progress - value from 0 - 1.
 * @param {number} maxProgress - max progress value. Represents the total units of work to be done.
 */
export interface ProcessingCreateClipVideo extends Omit<ProcessingVideo, 'type'> {
  type: 'ProcessingCreateClipVideo';
  maxProgress: number;
  parentVideoUuid?: UUID;
}

export interface ProcessingCreateMontageVideo extends Omit<ProcessingCreateClipVideo, 'type'> {
  type: 'ProcessingCreateMontageVideo';
}

export interface ProcessingMergeVideo extends Omit<ProcessingCreateClipVideo, 'type'> {
  type: 'ProcessingMergeVideo';
}

export type AnyProcessingVideo = ProcessingVideo | ProcessingCreateClipVideo | ProcessingCreateMontageVideo | ProcessingMergeVideo | ProcessingDemoVideo;
export type AnyMaxProgressProcessingVideo =  ProcessingCreateClipVideo | ProcessingCreateMontageVideo | ProcessingMergeVideo;

/**
 * segment times are in ms
 */
export interface MergedVideoSegment {
  fullVideoUuid: UUID;
  startTime: number;
  endTime: number;
  gameClassId?: number | GameClassId;
  queueId?: number;
}

/**
 * The MergedVideo type is for videos that combine multiple video files together whereas _Clip's deal with a single origin file.
 * The issue with combining different videos has nothing to do with the video files, but with the fact that different games can be combined.
 * The game events need special handling to work.
 *
 * @param {number} result.duration is in ms
 */
export interface MergedVideo extends _Video {
  type: 'MergedVideo';
  segments: MergedVideoSegment[];
}

export type Video =
| StreamVideo
| VideoClip
| InstantReplay
| MontageClip
| MergedVideo
| ImportVideo
| TFTVideo
| DemoVideo
| AnyProcessingVideo;

type LeagueTFTVideo = (StreamVideo | VideoClip | InstantReplay | MontageClip | MergedVideo) & {
  gameClassId: typeof CLASS_IDS.LEAGUE_TFT;
  queueId?: number;
};

export type TFTVideo = LeagueTFTVideo & {
  queueId: TFTQueueId;
};

export const checkIsStreamVideo = (video: Video): video is StreamVideo => {
  return video.type === undefined;
};

export const checkIsInstantReplay = (video: Video): video is InstantReplay => {
  return video.type === 'InstantReplay';
};

export const checkIsVideoClip = (video: Video): video is VideoClip => {
  return video.type === 'VideoClip';
};

export const checkIsMontageClip = (video: Video): video is MontageClip => {
  return video.type === 'MontageClip';
};

export const checkIsMergedVideo = (video: Video): video is MergedVideo => video.type === 'MergedVideo';
export const checkIsProcessingVideo = (video: Video): video is ProcessingVideo => video.type === 'ProcessingVideo';
export const checkIsImportVideo = (video: Video): video is ImportVideo => video.type === 'ImportVideo';
export const checkIsProcessingDemoVideo = (video: Video): video is ProcessingDemoVideo => video.type === 'ProcessingDemoVideo';
export const checkIsDemoVideo = (video: Video): video is DemoVideo => video.type === 'DemoVideo';
export const checkIsProcessingCreateClipVideo = (video: Video): video is ProcessingCreateClipVideo => video.type === 'ProcessingCreateClipVideo';
export const checkIsProcessingMergeVideo = (video: Video): video is ProcessingMergeVideo => video.type === 'ProcessingMergeVideo';
export const checkIsAnyProcessingVideo = (video: Video): video is AnyProcessingVideo => (
  video.type === 'ProcessingVideo'
  || video.type === 'ProcessingCreateClipVideo'
  || video.type === 'ProcessingCreateMontageVideo'
  || video.type === 'ProcessingMergeVideo'
  || video.type === 'ProcessingDemoVideo'
);
export const checkIsAnyMaxProgressProcessingVideo = (video: Video): video is AnyMaxProgressProcessingVideo => (
  video.type === 'ProcessingCreateClipVideo'
  || video.type === 'ProcessingCreateMontageVideo'
  || video.type === 'ProcessingMergeVideo'
);

export const isLeagueTFTVideo = (video: Video | _Video): video is LeagueTFTVideo => video.gameClassId === CLASS_IDS.LEAGUE_TFT;
export const isTFTVideo = (video: Video): video is TFTVideo => isLeagueTFTVideo(video) && !!video.queueId && isTFTQueueId(video.queueId);

export interface LibraryMetadata {
  favoritedVideos: Partial<Dictionary<boolean>>;
  newVideos: Partial<Dictionary<boolean>>;
}

export type RenameSource = MixpanelEventSource<'Video Replay Page' | 'Video Context Menu'>;

export interface VideoRenamedMeta {
  renameSource?: RenameSource;
}

export type TabChangedSource = MixpanelEventSource<'Add Clips Button' | 'Add Montage Button' | 'Statistics Button' | 'Bookmark Comment Button'| 'Right Panel Tab Button' | 'Automatic' | 'Initial' | 'Hotkey'>;

interface VideoRenamedPayload {
  videoUuid: UUID;
  newName: string;
}

interface MultipleVideoUuidsPayload {
  videoUuids: UUID[];
}

type VideoMissingPayload = MultipleVideoUuidsPayload;
type VideoLocatedPayload = MultipleVideoUuidsPayload;
type VideoFavoritedPayload = MultipleVideoUuidsPayload;
type VideoUnfavoritedPayload = MultipleVideoUuidsPayload;
type AddedToMontageQueuePayload = MultipleVideoUuidsPayload;
type RemovedFromMontageQueuePayload = MultipleVideoUuidsPayload;

interface VideoRecycledPayload {
  videoUuids: UUID[];
  dateRecycled: number;
}

export type RecordingSource = 'Automatic' | 'Automatic Restart' | 'Check For League Auto Start' | 'Check For Session Stream Start' | 'Desktop Window' | 'Hotkey' | 'Ingame Window' | 'Secondary Window' | 'Session';
export type RecordingType = 'Desktop' | 'Game';

export interface VideoSavedMeta {
  recordingType?: RecordingType;
  error?: string; // for when ow-obs.exe crashes. The video file is created.
}

export interface InstantReplaySavedMeta {
  recordingType?: RecordingType;
  webcamEnabled?: boolean;
}

export type RecycleSource = MixpanelEventSource<'Video Replay Page' | 'Video Management Drawer' | 'Video Context Menu' | 'Folder Context Menu' | 'Video Card' | 'Selection Tools'>;
export type DeleteSource = RecycleSource;

export interface VideoRecycledMeta {
  recycleSource?: RecycleSource;
}

type VideoRestoredPayload = MultipleVideoUuidsPayload;

interface VideoLocationUpdatedPayload {
  videoUuid: UUID;
  newLocation: string;
}

export interface VideoDeletedMeta {
  deleteFromDisk: true;
  deleteSource?: DeleteSource;
  errorTransform?: (video: Video, error: Error) => any;
}

export interface DeleteSuccess {
  videoUuid: UUID;
  status: 'fulfilled';
}

export interface DeleteFailure {
  videoUuid: UUID;
  status: 'rejected';
  error: Error;
}

type DeleteResult = DeleteSuccess | DeleteFailure;

export type VideosDeletedPayload = DeleteResult[];

export type FavoriteSource = MixpanelEventSource<'Video Replay Page' | 'Video Card' | 'Selection Tools'>;

interface VideoFavoritedMeta {
  favoriteSource?: FavoriteSource;
}

type VideoUnfavoritedMeta = VideoFavoritedMeta;

export interface ReencodeConfig {
  crop?: {
    option: CropOption;
    aspectRatio: {
      width: number;
      height: number;
    };
  };
}

export interface CreateVideoClipMeta {
  combineClips?: boolean;
  userProvidedName?: string;
  reencodeConfig?: ReencodeConfig;
  processingVideoUuids?: UUID[];
  onProgressAdd?: (progress: number) => void;
}

export interface CreateMontageClipMeta {
  source?: 'automatic' | 'manual';
}

export interface CreateMontageClipFromQueueMeta extends CreateMontageClipMeta {
  originalMontageQueueUuids: UUID[];
}

export interface VideoLibraryFolder {
  uuid: UUID;
  name: string;
  created: number;
}

export interface FolderCreatedPayload {
  folder: VideoLibraryFolder;
  parentUuid?: UUID;
}

interface FolderRenamedPayload {
  folderUuid: UUID;
  newName: string;
}

interface FolderRecycledPayload {
  folderUuids: UUID[];
  dateRecycled: number;
}

interface FolderRestoredPayload {
  folderUuids: UUID[];
};

export type FolderRecycledMeta = VideoRecycledMeta;

interface FolderMovedPayload {
  folderUuid: UUID;
  destinationUuid?: UUID;
}

interface VideoMovedPayload {
  videoUuid: UUID;
  destinationUuid?: UUID;
}

interface FoldersMovedPayload {
  folderUuids: UUID[];
  destinationUuid?: UUID;
}

interface VideosMovedPayload {
  videoUuids: UUID[];
  destinationUuid?: UUID;
}

export interface VideoImportOptions {
  favorite?: boolean;
  parentUuid?: UUID;
}

interface ProcessingVideosAddedPayload extends VideoImportOptions {
  videos: AnyProcessingVideo[];
}

interface ProcessingVideosRemovedPayload {
  videoUuids: UUID[];
}

interface ProcessingVideoProgressUpdatedPayload {
  videoUuid: UUID;
  progress: number;
}

interface VideoImportedPayload extends VideoImportOptions {
  video: ImportVideo;
}

interface FolderChildrenRestored {
  videoUuids: UUID[];
  folderUuids: UUID[];
}

interface ExportVideosParams {
  videoUuids: UUID[];
  folderUuids: UUID[];
  destination: string;
}

export interface DownloadDemoVideoParams {
  uuid: UUID;
  name: string;
  url: string;
  gameClassId: GameClassId;
}

interface VideoLibraryState {
  videos: Partial<Dictionary<Video>>;
  videoMissing: Partial<Dictionary<boolean>>;
  newVideos: Partial<Dictionary<boolean>>;
  favoritedVideos: Partial<Dictionary<boolean>>;
  recycledVideos: Partial<Dictionary<number>>;

  folders: Partial<Dictionary<VideoLibraryFolder>>;
  recycledFolders: Partial<Dictionary<number>>;

  parentDict: Partial<Dictionary<UUID>>;

  montageQueue: Partial<Dictionary<boolean>>;
  availableFreeSpace?: number;

  demoVideoTemp: Partial<Dictionary<ProcessingDemoVideo | DemoVideo>>;
}

const initialState: VideoLibraryState = {
  videos: {},
  videoMissing: {},
  newVideos: {},
  favoritedVideos: {},
  recycledVideos: {},
  folders: {},
  recycledFolders: {},
  parentDict: {},
  montageQueue: {},
  demoVideoTemp: {},
};

const videoLibrarySlice = createSlice({
  name,
  initialState,
  reducers: {
    videoSaved: {
      reducer(state, action: PayloadAction<StreamVideo>) {
        const video = action.payload;
        state.videos = update(state.videos, {
          $merge: { [video.uuid]: video },
        });
        state.newVideos = update(state.newVideos, {
          $merge: { [video.uuid]: true },
        });
      },
      prepare(payload: StreamVideo, meta?: VideoSavedMeta) {
        return { payload, meta };
      },
    },
    replaySaved: {
      reducer(state, action: PayloadAction<InstantReplay>) {
        const video = action.payload;
        state.videos = update(state.videos, {
          $merge: { [video.uuid]: video },
        });
        state.newVideos = update(state.newVideos, {
          $merge: { [video.uuid]: true },
        });
      },
      prepare(payload: InstantReplay, meta?: InstantReplaySavedMeta) {
        if (isTFTVideo(payload)) {
          // Instant replay uses file names when showing the video name on a video card. Overwolf's replay capture api saves the file as "League of Legends (date/time)".
          // Add a userProvidedName so TFT instant replays don't show up as "League of Legends".
          payload.userProvidedName =
            humanizeVideoName(payload)
              .replace(
                'League of Legends',
                t(`gameEvents:${TeamfightTacticsGameEvent.PSEUDO_CLASS_ID}.title`),
              );
        }
        return { payload, meta };
      },
    },
    videoRenamed: {
      reducer(state, action: PayloadAction<VideoRenamedPayload>) {
        const { videoUuid, newName } = action.payload;
        state.videos = update(state.videos, {
          [videoUuid]: video => video ? update(video, { userProvidedName: { $set: newName } }) : video,
        });
      },
      prepare(payload: VideoRenamedPayload, meta?: VideoRenamedMeta) {
        return { payload, meta };
      },
    },
    videoLocationUpdated(state, action: PayloadAction<VideoLocationUpdatedPayload>) {
      const { videoUuid, newLocation } = action.payload;
      state.videos = update(state.videos, {
        [videoUuid]: video => video ? update(video, { result: { file_path: { $set: newLocation } } }) : video,
      });
      state.videoMissing[videoUuid] = false;
    },
    videoLocated(state, action: PayloadAction<VideoLocatedPayload>) {
      const { payload: { videoUuids } } = action;
      const dict = fromPairs(videoUuids.map(uuid => ([uuid, false])));
      state.videoMissing = update(state.videoMissing, { $merge: dict });
    },
    videoMissing(state, action: PayloadAction<VideoMissingPayload>) {
      const { payload: { videoUuids } } = action;
      const dict = fromPairs(videoUuids.map(uuid => ([uuid, true])));
      state.videoMissing = update(state.videoMissing, { $merge: dict });
    },
    // videoDeleted: {
    //   reducer(state, action: PayloadAction<UUID>) {
    //     return update(state, {
    //       videos: { $unset: [action.payload] },
    //       recycledVideos: { $unset: [action.payload] },
    //       favoritedVideos: { $unset: [action.payload] },
    //     });
    //   },
    //   prepare(payload: UUID, meta?: VideoDeletedMeta) {
    //     return { payload, meta };
    //   },
    // },
    videoRecycled: {
      reducer(state, action: PayloadAction<VideoRecycledPayload>) {
        const { payload: { videoUuids, dateRecycled } } = action;
        const dict = fromPairs(videoUuids.map(uuid => ([uuid, dateRecycled])));
        state.recycledVideos = update(state.recycledVideos, { $merge: dict });
      },
      prepare(payload: VideoRecycledPayload, meta?: VideoRecycledMeta) {
        return { payload, meta };
      },
    },
    videoRestored(state, action: PayloadAction<VideoRestoredPayload>) {
      const { payload: { videoUuids } } = action;
      state.recycledVideos = update(state.recycledVideos, { $unset: videoUuids });
    },
    allVideosRestored(state) {
      state.recycledVideos = {};
    },
    newVideoDismissed(state, action: PayloadAction<UUID>) {
      state.newVideos = update(state.newVideos, {
        $unset: [action.payload],
      });
    },
    videoFavorited: {
      reducer(state, action: PayloadAction<VideoFavoritedPayload>) {
        const { payload: { videoUuids } } = action;
        const dict = Object.fromEntries(videoUuids.map(uuid => ([uuid, true])));
        state.favoritedVideos = update(state.favoritedVideos, { $merge: dict });
      },
      prepare(payload: VideoFavoritedPayload, meta?: VideoFavoritedMeta) {
        return { payload, meta };
      },
    },
    videoUnfavorited: {
      reducer(state, action: PayloadAction<VideoUnfavoritedPayload>) {
        const { payload: { videoUuids } } = action;
        state.favoritedVideos = update(state.favoritedVideos, { $unset: videoUuids });
      },
      prepare(payload: VideoFavoritedPayload, meta?: VideoUnfavoritedMeta) {
        return { payload, meta };
      },
    },

    folderCreated(state, action: PayloadAction<FolderCreatedPayload>) {
      const { payload: { folder, parentUuid } } = action;
      state.folders[folder.uuid] = folder;
      state.parentDict[folder.uuid] = parentUuid;
    },
    folderRenamed(state, action: PayloadAction<FolderRenamedPayload>) {
      const { folderUuid, newName } = action.payload;
      state.folders = update(state.folders, {
        [folderUuid]: folder => folder ? update(folder, { name: { $set: newName } }) : folder,
      });
    },
    folderRecycled: {
      reducer(state, action: PayloadAction<FolderRecycledPayload>) {
        const { payload: { folderUuids, dateRecycled } } = action;
        const dict = fromPairs(folderUuids.map(uuid => ([uuid, dateRecycled])));
        state.recycledFolders = update(state.recycledFolders, { $merge: dict });
      },
      prepare(payload: FolderRecycledPayload, meta?: FolderRecycledMeta) {
        return { payload, meta };
      },
    },
    folderRestored(state, action: PayloadAction<FolderRestoredPayload>) {
      const { folderUuids } = action.payload;
      state.recycledFolders = update(state.recycledFolders, { $unset: folderUuids });
    },
    // folderDeleted(state, action: PayloadAction<UUID>) {
    //   delete(state.folders[action.payload]);
    //   delete(state.parentDict[action.payload]);
    //   delete(state.recycledVideos[action.payload]);
    // },
    folderMoved(state, action: PayloadAction<FolderMovedPayload>) {
      const { folderUuid, destinationUuid } = action.payload;
      state.parentDict[folderUuid] = destinationUuid;
    },
    videoMoved(state, action: PayloadAction<VideoMovedPayload>) {
      const { videoUuid, destinationUuid } = action.payload;
      state.parentDict[videoUuid] = destinationUuid;
    },
    foldersMoved(state, action: PayloadAction<FoldersMovedPayload>) {
      const { folderUuids, destinationUuid } = action.payload;
      for (const uuid of folderUuids) {
        state.parentDict[uuid] = destinationUuid;
      }
    },
    videosMoved(state, action: PayloadAction<VideosMovedPayload>) {
      const { videoUuids, destinationUuid } = action.payload;
      for (const uuid of videoUuids) {
        state.parentDict[uuid] = destinationUuid;
      }
    },
    // * used when a parent folder is recycled
    folderChildrenRestored(state, action: PayloadAction<FolderChildrenRestored>) {
      const { folderUuids, videoUuids } = action.payload;
      return update(state, {
        recycledFolders: { $unset: folderUuids },
        recycledVideos: { $unset: videoUuids },
      });
    },

    videoClipConvertedToMontageClip(state, action: PayloadAction<UUID>) {
      const uuid = action.payload;
      const video = state.videos[uuid];
      if (video && checkIsVideoClip(video)) {
        state.videos = update(state.videos, { [uuid]: (video: Video | undefined) => (
          video ? update(video, { type: { $set: 'MontageClip' } }) : undefined
        ) });
      }
    },
    addedToMontageQueue(state, action: PayloadAction<AddedToMontageQueuePayload>) {
      const uuids = action.payload.videoUuids;
      const dict = Object.fromEntries(uuids.map(uuid => ([uuid, true])));
      state.montageQueue = update(state.montageQueue, {
        $merge: dict,
      });
    },
    removedFromMontageQueue(state, action: PayloadAction<RemovedFromMontageQueuePayload>) {
      const uuids = action.payload.videoUuids;
      state.montageQueue = update(state.montageQueue, {
        $unset: uuids,
      });
    },
    processingVideosAdded(state, action: PayloadAction<ProcessingVideosAddedPayload>) {
      const { videos, parentUuid, favorite } = action.payload;
      const videosObj = Object.fromEntries(videos.map(video => [video.uuid, video]));
      state.videos = update(state.videos, {
        $merge: videosObj,
      });
      if (parentUuid) {
        state.parentDict = update(state.parentDict, {
          $merge: Object.fromEntries(videos.map(video => [video.uuid, parentUuid])),
        });
      }
      if (favorite) {
        state.favoritedVideos = update(state.favoritedVideos, {
          $merge: Object.fromEntries(videos.map(video => [video.uuid, true])),
        });
      }
    },
    processingVideosRemoved(state, action: PayloadAction<ProcessingVideosRemovedPayload>) {
      const { videoUuids } = action.payload;
      const filteredUuids = videoUuids.filter(uuid => {
        const video = state.videos[uuid];
        return video && (checkIsAnyProcessingVideo(video));
      });
      state.videos = update(state.videos, {
        $unset: filteredUuids,
      });
      state.parentDict = update(state.parentDict, {
        $unset: filteredUuids,
      });
      state.favoritedVideos = update(state.favoritedVideos, {
        $unset: filteredUuids,
      });
    },
    processingVideoProgressUpdated(state, action: PayloadAction<ProcessingVideoProgressUpdatedPayload>) {
      const { videoUuid, progress } = action.payload;
      const video = state.videos[videoUuid];
      if (video && checkIsAnyProcessingVideo(video)) {
        state.videos[videoUuid] = update(state.videos[videoUuid], {
          $merge: {
            progress,
          },
        });
      }
    },
    processingCreateClipVideoMaxProgressUpdated(state, action: PayloadAction<{ videoUuid: UUID; maxProgress: number}>) {
      const { videoUuid, maxProgress } = action.payload;
      const video = state.videos[videoUuid];
      if (video && checkIsAnyMaxProgressProcessingVideo(video)) {
        state.videos[videoUuid] = update(state.videos[videoUuid], {
          maxProgress: { $set: maxProgress },
        });
      }
    },
    processingCreateClipVideoProgressAdded(state, action: PayloadAction<ProcessingVideoProgressUpdatedPayload>) {
      const { videoUuid, progress } = action.payload;
      const video = state.videos[videoUuid];
      if (video && checkIsAnyMaxProgressProcessingVideo(video)) {
        state.videos[videoUuid] = update(state.videos[videoUuid], {
          progress: (p) => p + progress / video.maxProgress,
        });
      }
    },
    videoImported(state, action: PayloadAction<VideoImportedPayload>) {
      const { video, parentUuid, favorite } = action.payload;
      state.videos = update(state.videos, {
        $merge: { [video.uuid]: video },
      });
      state.newVideos = update(state.newVideos, {
        $merge: { [video.uuid]: true },
      });
      if (parentUuid) {
        state.parentDict[video.uuid] = parentUuid;
      }
      if (favorite) {
        state.favoritedVideos[video.uuid] = true;
      }
    },
    demoVideoProcessing(state, action: PayloadAction<ProcessingDemoVideo>) {
      state.demoVideoTemp[action.payload.uuid] = action.payload;
    },
    demoVideoProgressUpdated(state, action: PayloadAction<{ uuid: UUID; progress: number }>) {
      const { uuid, progress } = action.payload;
      const video = state.videos[uuid];
      const temp = state.demoVideoTemp[uuid];
      if (video) {
        if (video.type === 'ProcessingDemoVideo') {
          video.progress = progress;
        }
      } else if (temp) {
        if (temp.type === 'ProcessingDemoVideo') {
          temp.progress = progress;
        }
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(deleteVideoAC.done, (state, action) => {
      return update(state, {
        videos: { $unset: [action.payload.params] },
        recycledVideos: { $unset: [action.payload.params] },
        favoritedVideos: { $unset: [action.payload.params] },
        newVideos: { $unset: [action.payload.params] },
      });
    });
    builder.addCase(deleteVideosAC.done, (state, action) => {
      const successfulDeletions = action.payload.result.filter((r): r is DeleteSuccess => r.status === 'fulfilled');
      const videoUuids = successfulDeletions.map(({ videoUuid }) => videoUuid);
      return update(state, {
        videos: { $unset: videoUuids },
        recycledVideos: { $unset: videoUuids },
        favoritedVideos: { $unset: videoUuids },
        newVideos: { $unset: videoUuids },
      });
    });
    builder.addCase(loadVideoDatabaseAC.done, (state, action) => {
      state.videos = action.payload.result || {};
    });
    builder.addCase(loadLibraryMetadataDatabaseAC.done, (state, action) => {
      const { favoritedVideos, newVideos } = action.payload.result;
      state.favoritedVideos = favoritedVideos;
      state.newVideos = newVideos;
    });
    builder.addCase(loadVideoRecycleBinDatabaseAC.done, (state, action) => {
      state.recycledVideos = action.payload.result || {};
    });
    builder.addCase(createVideoClipsAC.done, (state, action) => {
      const { params, result: videos } = action.payload;
      const parentUuid = state.parentDict[params.video.uuid];
      videos.forEach(video => {
        state.videos[video.uuid] = video;
        state.newVideos[video.uuid] = true;
        if (parentUuid) {
          state.parentDict[video.uuid] = parentUuid;
        }
      });
    });
    builder.addCase(createMergedVideoAC.done, (state, action) => {
      const { result: video } = action.payload;
      state.videos[video.uuid] = video;
      state.newVideos[video.uuid] = true;
    });
    builder.addCase(deleteAllRecycledVideosAC.done, (state, action) => {
      state.recycledVideos = {};
    });

    builder.addCase(deleteFolderAC.done, (state, action) => {
      const { params } = action.payload;
      delete(state.folders[params]);
      delete(state.parentDict[params]);
      delete(state.recycledVideos[params]);
    });
    builder.addCase(loadFolderDatabaseAC.done, (state, action) => {
      state.folders = action.payload.result || {};
    });
    builder.addCase(loadFolderRecycleBinDatabaseAC.done, (state, action) => {
      state.recycledFolders = action.payload.result || {};
    });
    builder.addCase(loadLibraryItemParentDatabaseAC.done, (state, action) => {
      state.parentDict = action.payload.result || {};
    });

    {
      const async = getAvailableFreeSpaceAC;
      builder.addCase(async.done, (state, action) => {
        state.availableFreeSpace = action.payload.result;
      });
    }

    builder.addCase(downloadDemoVideoAC.done, (state, action) => {
      const { video } = action.payload.result;
      if (state.videos[video.uuid]) {
        state.videos[video.uuid] = video;
      } else {
        state.demoVideoTemp[video.uuid] = video;
      }
    });

    builder.addCase(downloadDemoVideoAC.failed, (state, action) => {
      const { uuid } = action.payload.params;
      return update(state, {
        videos: { $unset: [uuid] },
        demoVideoTemp: { $unset: [uuid] },
        newVideos: { $unset: [uuid] },
      });
    });

    builder.addCase(addDemoVideoToLibrary, (state) => {
      state.videos = update(state.videos, { $merge: state.demoVideoTemp });
      Object.values(state.videos).forEach(video => {
        if (video && (checkIsDemoVideo(video) || checkIsProcessingDemoVideo(video))) {
          state.newVideos[video.uuid] = true;
        }
      });
    });
  },
});

export const {
  addedToMontageQueue,
  allVideosRestored,
  demoVideoProcessing,
  demoVideoProgressUpdated,
  folderChildrenRestored,
  folderCreated,
  folderMoved,
  folderRecycled,
  folderRenamed,
  folderRestored,
  foldersMoved,
  newVideoDismissed,
  processingCreateClipVideoMaxProgressUpdated,
  processingCreateClipVideoProgressAdded,
  processingVideosAdded,
  processingVideoProgressUpdated,
  processingVideosRemoved,
  removedFromMontageQueue,
  replaySaved,
  videoClipConvertedToMontageClip,
  videoFavorited,
  videoImported,
  videoLocated,
  videoLocationUpdated,
  videoMissing,
  videoMoved,
  videoRecycled,
  videoRenamed,
  videoRestored,
  videoSaved,
  videosMoved,
  videoUnfavorited,
} = videoLibrarySlice.actions;

export const videoLibraryReducer = videoLibrarySlice.reducer;
