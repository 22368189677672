import { DiscoverChallengeFragment } from '@/apollo/discover/fragments/__generated__/DiscoverChallengeFragment';
import { DiscoverSubmissionFragment } from '@/apollo/discover/fragments/__generated__/DiscoverSubmissionFragment';
import { VideoBookmark } from '@/features/bookmarks/bookmark-slice';
import { TrackedUpload } from '@/features/upload-tracker/upload-tracker-slice';
import { Video } from '@/features/video-library/video-library-slice';
import { humanizeVideoName } from '@/utils/string-manips';

export type CompareFn<T> = (a: T, b: T) => number;
const isMultiDimensional = <T>(A: T[] | T[][]): A is T[][] => Array.isArray(A[0]);

export function createMultiCompare<T>(fns: CompareFn<T>[]): CompareFn<T>;
export function createMultiCompare<T>(...args: CompareFn<T>[]): CompareFn<T>;
export function createMultiCompare<T>(...args: CompareFn<T>[] | CompareFn<T>[][]): CompareFn<T> {
  const fns = isMultiDimensional(args) ? args[0] : args;
  return function compare(lhs, rhs) {
    for (const fn of fns) {
      const c = fn(lhs, rhs);
      if (c !== 0) {
        return c;
      }
    }
    return 0;
  };
}

export function compareProperty<T1, T2>(accessor: (t: T1) => T2) {
  return function (compare: CompareFn<T2>): CompareFn<T1> {
    return function (lhs, rhs) {
      return compare(accessor(lhs), accessor(rhs));
    };
  };
}

function OrUndefinedComparator<T>(comparator: IComparator<T>): IComparator<T | undefined> {
  return class OrUndefined {
    static guardAgainstUndefined(lhs: T | undefined, rhs: T | undefined, fn: CompareFn<T>) {
      if (lhs === undefined && rhs === undefined) {
        return 0;
      }
      if (lhs === undefined) {
        return 1;
      }
      if (rhs === undefined) {
        return -1;
      }

      return fn(lhs, rhs);
    }

    static asc(lhs: T | undefined, rhs: T | undefined) {
      return OrUndefined.guardAgainstUndefined(lhs, rhs, comparator.asc);
    }

    static desc(lhs: T | undefined, rhs: T | undefined) {
      return OrUndefined.guardAgainstUndefined(lhs, rhs, comparator.desc);
    }
  };
}


export interface IComparator<T> {
  asc: CompareFn<T>;
  desc: CompareFn<T>;
}

export const NumberComparator: IComparator<number> = class {
  static asc(lhs: number, rhs: number) {
    if (lhs < rhs) return -1;
    if (lhs > rhs) return 1;
    return 0;
  }
  static desc(lhs: number, rhs: number) {
    if (lhs < rhs) return 1;
    if (lhs > rhs) return -1;
    return 0;
  }
};

export const NumberOrUndefinedComparator: IComparator<number | undefined> = OrUndefinedComparator(NumberComparator);

export const StringComparator: IComparator<string> = class {
  static asc(lhs: string, rhs: string) {
    return lhs.localeCompare(rhs);
  }
  static desc(lhs: string, rhs: string) {
    return rhs.localeCompare(lhs);
  }
};

const compareVideoCreated = compareProperty((v: Video) => v.created);
const VideoCreatedComparator: IComparator<Video> = class {
  static asc = compareVideoCreated(NumberComparator.asc);
  static desc = compareVideoCreated(NumberComparator.desc);
};

const compareVideoName = compareProperty(humanizeVideoName);
const VideoNameComparator: IComparator<Video> = class {
  static asc = compareVideoName(StringComparator.asc);
  static desc = compareVideoName(StringComparator.desc);
};

const compareVideoDuration = compareProperty((v: Video) => v.result.duration);
const VideoDurationComparator: IComparator<Video> = class {
  static asc = compareVideoDuration(NumberComparator.asc);
  static desc = compareVideoDuration(NumberComparator.desc);
};

const compareVideoSize = compareProperty((v: Video) => v.size);
const VideoSizeComparator: IComparator<Video> = class {
  static asc = compareVideoSize(NumberComparator.asc);
  static desc = compareVideoSize(NumberComparator.desc);
};

const compareChallengeStartDate = compareProperty((c: DiscoverChallengeFragment) => new Date(c.startDate).getTime());
const ChallengeStartDateComparator: IComparator<DiscoverChallengeFragment> = class {
  static asc = compareChallengeStartDate(NumberComparator.asc);
  static desc = compareChallengeStartDate(NumberComparator.desc);
};

const compareChallengeEndDate = compareProperty((c: DiscoverChallengeFragment) => new Date(c.endDate).getTime());
const ChallengeEndDateComparator: IComparator<DiscoverChallengeFragment> = class {
  static asc = compareChallengeEndDate(NumberComparator.asc);
  static desc = compareChallengeEndDate(NumberComparator.desc);
};

const compareChallengeName = compareProperty((c: DiscoverChallengeFragment) => c.name);
const ChallengeNameComparator: IComparator<DiscoverChallengeFragment> = class {
  static asc = compareChallengeName(StringComparator.asc);
  static desc = compareChallengeName(StringComparator.desc);
};

const compareSubmissionLikeCount = compareProperty((s: DiscoverSubmissionFragment) => s.likeCount);
const SubmissionLikeCountComparator: IComparator<DiscoverSubmissionFragment> = class {
  static asc = compareSubmissionLikeCount(NumberComparator.asc);
  static desc = compareSubmissionLikeCount(NumberComparator.desc);
};

const compareBookmarkTimestamp = compareProperty((b: VideoBookmark) => b.timestamp);
const BookmarkTimestampComparator: IComparator<VideoBookmark> = class {
  static asc = compareBookmarkTimestamp(NumberComparator.asc);
  static desc = compareBookmarkTimestamp(NumberComparator.desc);
};

const compareUploadDate = compareProperty((u: TrackedUpload) => u.uploadDate ?? 0);
const UploadsHistoryDateComparator: IComparator<TrackedUpload> = class {
  static asc = compareUploadDate(NumberComparator.asc);
  static desc = compareUploadDate(NumberComparator.desc);
};

type CompareFnFactory<T, P = T> = (compare: CompareFn<P>) => CompareFn<T>;
type ComparatorFactory<T, P = T> = (factory: CompareFnFactory<T, P>) => IComparator<T>;

type GameNameCompareFnGenerator<T> = (compare: CompareFn<string>) => CompareFn<T>;
type VideoGameNameComparatorGenerator<T> = (compareFnGenerator: GameNameCompareFnGenerator<T>) => IComparator<T>;
const makeVideoGameNameComparator: VideoGameNameComparatorGenerator<Video> = (compareFnGenerator) => {
  return class {
    static asc = compareFnGenerator(StringComparator.asc);
    static desc = compareFnGenerator(StringComparator.desc);
  };
};

const makeVideoTimeUntilDeletedComparator: ComparatorFactory<Video, number> = (compareFnGenerator) => {
  return class {
    static asc = compareFnGenerator(NumberComparator.asc);
    static desc = compareFnGenerator(NumberComparator.desc);
  };
};

export const VideoComparators = {
  created: VideoCreatedComparator,
  name: VideoNameComparator,
  duration: VideoDurationComparator,
  size: VideoSizeComparator,
};

export const VideoComparatorFactories = {
  game: makeVideoGameNameComparator,
  timeUntilDeleted: makeVideoTimeUntilDeletedComparator,
};

export type VideoComparatorsDictionary = typeof VideoComparators & {
  game: IComparator<Video>;
  timeUntilDeleted: IComparator<Video>;
};

export const ChallengeComparators = {
  startDate: ChallengeStartDateComparator,
  endDate: ChallengeEndDateComparator,
  name: ChallengeNameComparator,
};

export const SubmissionComparators = {
  liked: SubmissionLikeCountComparator,
};

export const BookmarkComparators = {
  timestamp: BookmarkTimestampComparator,
};

export const UploadsHistoryComparator = {
  uploadDate: UploadsHistoryDateComparator,
};

// ------- Group Comparator ---------
const makeGroupVideoGameNameComparator: VideoGameNameComparatorGenerator<[string, Video[]]> = (compareFnGenerator) => {
  return class {
    static asc = compareFnGenerator(StringComparator.asc);
    static desc = compareFnGenerator(StringComparator.desc);
  };
};

const GroupVideoCreatedComparator: IComparator<[string, Video[]]> = class {
  static asc = compareProperty(([ms, _videos]: [string, Video[]]) => ms)(StringComparator.asc);
  static desc = compareProperty(([ms, _videos]: [string, Video[]]) => ms)(StringComparator.desc);
};

export const GroupComparators = {
  game: makeGroupVideoGameNameComparator,
  created: GroupVideoCreatedComparator,
};

export type GroupComparatorsDictionary = Omit<typeof GroupComparators, 'game'> & {
  game: IComparator<[string, Video[]]>;
};

export function makeComparator<T1, T2>(
  accessor: (t: T1) => T2,
  comparator: IComparator<T2>,
): IComparator<T1> {
  const asc = compareProperty(accessor)(comparator.asc);
  const desc = compareProperty(accessor)(comparator.desc);
  return { asc, desc };
}
