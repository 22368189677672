import { ApolloProvider, Reference, useSuspenseQuery } from '@apollo/client';
import { Theme } from '@insights-gaming/theme';
import { CreateVideoAPIParams, VideoUploadState } from '@insights-gaming/video-upload-slice';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LanguageIcon from '@mui/icons-material/Language';
import { Box, CssBaseline, GlobalStyles, IconButton, ListItemText, Menu, MenuItem, Stack, StackProps, SxProps } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { configureStore } from '@reduxjs/toolkit';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import localforage from 'localforage';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import mixpanelBrowser from 'mixpanel-browser';
import { Fragment, PropsWithChildren, Suspense, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { initReactI18next, useTranslation } from 'react-i18next';
import { Provider, useDispatch, useSelector } from 'react-redux';
import * as portals from 'react-reverse-portal';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import createSagaMiddleware, { SagaIterator } from 'redux-saga';
import { call, fork, select } from 'redux-saga/effects';
import thunkMiddleware from 'redux-thunk';

import { createApolloClient } from '@/apollo/client';
import Loading from '@/app/Loading';
import { reducer, WebRootReducer } from '@/app/rootReducer.web';
import AppWrapper from '@/components/AppWrapper';
import { StyledSnackbarProvider } from '@/components/StyledSnackbarProvider';
import authenticationSaga from '@/features/authentication/authentication-saga';
import { getAuthSessionChecked, getLoggingOut } from '@/features/authentication/authentication-selector';
import { LoginCallback } from '@/features/authentication/LoginCallback';
import LoginDialog from '@/features/authentication/LoginDialog';
import { PasswordReset } from '@/features/authentication/PasswordReset';
import { useLogout } from '@/features/authentication/useLogout';
import { UserPortalContext } from '@/features/authentication/UserPortalContext.web';
import { UserProfileQuery, useUserProfileSubscription } from '@/features/authentication/UserProfileContext';
import { VerifyCallback } from '@/features/authentication/VerifyCallback';
import { SVGGradientDefs } from '@/features/custom-theme/SVGGradientDefs';
import { TooltipContainer, TooltipContainerContext } from '@/features/desktop-window/TooltipContainerContext';
import { WebGlobalContext } from '@/features/global-context/WebGlobalContext';
import LegacyPigeonRouting from '@/features/pigeon-router/LegacyPigeonRouting';
import InvitationRouting from '@/features/squad/Invitation/invitation.routing';
import PublicVideoRouting from '@/features/squad/PublicVideo/public-video.routing';
import SquadRouting, { useSelectedTeamId } from '@/features/squad/squad.routing';
import { SquadError } from '@/features/squad/SquadError';
import { SelectedTeamContext, TeamInjector } from '@/features/squad/TeamContext';
import { TeamVideoCard_VideoFragment } from '@/features/squad/TeamDirectory/TeamVideoCard';
import { TeamSelector } from '@/features/squad/TeamSelector';
import { TeamSubscriptionManager } from '@/features/squad/TeamSubscriptionManager';
import { TeamVideoRoutePublicFallbackWithErrorBounds } from '@/features/squad/TeamVideo/TeamVideoPublicFallback';
import { MultiSelectAtomsContext, useMultiSelectAtomsContextValue } from '@/features/video-library/atoms/multiSelectAtoms';
import CustomDragLayer from '@/features/video-library/CustomDragLayer';
import { CustomDragLayerInjector } from '@/features/video-library/CustomDragLayerContext';
import { UploadQueue } from '@/features/video-upload/upload-queue/UploadQueue';
import { AbortUploadContext, useAbortUploadContextValue } from '@/features/video-upload/upload-queue/useAbort';
import { allVideoUploadSagas, UploadVideoInput } from '@/features/video-upload/upload-queue/video-upload-slice';
import { clearFailedUploads, clearUploadedVideosTemp } from '@/features/video-upload/upload-queue/video-upload-slice/video-upload-slice.app';
import { getFragmentData, gql } from '@/graphql/__generated__';
import { VideoPublicity } from '@/graphql/__generated__/graphql';
import { mixpanelProxy } from '@/mixpanel/mixpanel-proxy';
import { identityManagement } from '@/mixpanel/mixpanel-saga.web';
import theme from '@/theme';
import promisifySaga from '@/utils/promisify-saga';

console.log(
  `%c${process.env.APPVERSION} ${process.env.BUILDTIME} ${process.env.COMMITSHA}`,
  'background: #00ff00',
);

const noUserSelectStyles = (
  <GlobalStyles
  styles={{
    '*': {
      userSelect: 'none',
    },
    // body: {
    //   backgroundColor: 'transparent',
    // },
  }}
  />
);

const listStyles = (
  <GlobalStyles
  styles={{
    ul: {
      margin: 0,
      paddingInlineStart: 27,
    },
    ol: {
      margin: 0,
      paddingInlineStart: 27,
    },
  }}
  />
);

const mixpanel = process.env.NODE_ENV === 'development' ? mixpanelProxy : mixpanelBrowser;
if (process.env.REACT_APP_MIXPANEL_TOKEN) {
  const mixpanels = [
    mixpanel,
  ];
  if (mixpanel !== mixpanelBrowser) {
    // The mixpanel proxy depends on mixpanel-browser being initialized to work
    // and this allows us to call both the library's init function as well as the proxy's
    mixpanels.push(mixpanelBrowser);
  }
  const token = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanels.forEach(mp => {
    mp.init(token, { debug: process.env.NODE_ENV === 'development' });
  });
}

function createStore() {
  const apollo = createApolloClient();
  const sagaMiddleware = createSagaMiddleware({
    context: {
      authSessionStore: localforage.createInstance({ storeName: 'AuthSession' }),
      apollo,
      mixpanel,
    },
  });

  const store = configureStore({
    reducer,
    middleware: [
      thunkMiddleware,
      sagaMiddleware,
      // logger,
    ],
  });

  function getUploadState(state: WebRootReducer) {
    return state.videoUpload;
  }

  function getUploadStateSaga() {
    return select(getUploadState);
  }

  function makeUploadSagaSelector<T>(selector: (state: ReturnType<typeof getUploadState>) => T) {
    return call(function* () {
      const state: VideoUploadState<UploadVideoInput> = yield getUploadStateSaga();
      return selector(state);
    });
  }

  const outOfBoxSagas = allVideoUploadSagas({
      async createVideoApi({ publicity, ...input }: CreateVideoAPIParams) {
        const result = await apollo.client.mutate({
          mutation: gql(`
            mutation CreateUploadVideo2Mutation($input: CreateUploadedVideo2Input!) {
              createUploadedVideo2(input: $input) {
                __typename
                uploadUrl
                video {
                  id
                  directory {
                    id
                  }
                  ...TeamVideoCard_VideoFragment
                }
              }
            }
          `),
          variables: {
            input: {
              ...input,
              publicity: publicity as VideoPublicity,
            },
          },
          update: (cache, { data }) => {
            if (!data?.createUploadedVideo2) {
              return;
            }
            const video = data.createUploadedVideo2.video;
            if (video.directory) {
              cache.modify({
                id: cache.identify(video.directory),
                fields: {
                  queryVideos(existing, { readField }) {
                    const newVideoRef = cache.writeFragment({
                      data: getFragmentData(TeamVideoCard_VideoFragment, video),
                      fragment: TeamVideoCard_VideoFragment,
                      fragmentName: 'TeamVideoCard_VideoFragment',
                    });
                    if (!existing || !newVideoRef || existing.videos.some((videoRef: Reference) => readField('id', videoRef) === video.id)) {
                      return existing;
                    }
                    return {
                      ...existing,
                      videos: [newVideoRef, ...existing.videos],
                    };
                  },
                },
              });
            }
          },
        });
        const createUploadedVideo2 = result.data?.createUploadedVideo2;
        if (!createUploadedVideo2) {
          throw new Error('Failed to create video');
        }
        return createUploadedVideo2;
      },
      *getUploadById(id: string) {
        return yield makeUploadSagaSelector(state => state.startedUploads[id]);
      },
      *getCurrentUpload() {
        return yield makeUploadSagaSelector(state => state.currentUpload);
      },
      *getUploadQueue(): SagaIterator {
        return yield makeUploadSagaSelector(state => state.uploadQueue);
      },
      getDefaultDest() {
        return undefined;
      },
      getVideoFileContent: ({ file }) => file,
      getVideoFileMetadata: ({ file }) => file,
      *getIsQueueEmpty() {
        return yield makeUploadSagaSelector(state => state.uploadQueue.length === 0);
      },
      *getStartedUploads() {
        return yield makeUploadSagaSelector(state => state.startedUploads);
      },
    });

  sagaMiddleware.run(function* () {
    yield fork(authenticationSaga);
    yield fork(promisifySaga);
    yield fork(function* () {
      yield fork(identityManagement);
    });
    yield fork(function* () {
      yield outOfBoxSagas;
    });
  });

  return {
    apollo,
    mixpanel,
    store,
  };
}

const {
  apollo,
  store,
} = createStore();

i18next
  .use(resourcesToBackend((lng: string, ns: string) => import(`@/locales/${lng}/${ns}.json`)))
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['common'],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default function BrowserEntry() {
  const [tooltipContainer, setTooltipContainer] = useState<HTMLDivElement | null>(null);
  const [stripePromise] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY || 'pk_live_WWjRl1ZV69yg3NtoXpiUwAcX00OEgbemcF'));
  return (
    <Provider store={store}>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {noUserSelectStyles}
            {listStyles}
            <SVGGradientDefs />
            <Suspense fallback={<Loading />}>
              <HelmetProvider>
                <StyledSnackbarProvider>
                  <WebGlobalContext.Provider value={{ mixpanel }}>
                    <ApolloProvider client={apollo.client}>
                      <DndProvider backend={HTML5Backend}>
                        <TooltipContainerContext.Provider value={tooltipContainer}>
                          <MultiSelectAtomsContext.Provider value={useMultiSelectAtomsContextValue()}>
                            <CustomDragLayerInjector>
                              <Elements stripe={stripePromise}>
                                <Helmet>
                                  <title>
                                    {process.env.REACT_APP_APPNAME}
                                  </title>
                                </Helmet>
                                <WrappedBrowserApp />
                              </Elements>
                              <CustomDragLayer />
                            </CustomDragLayerInjector>
                          </MultiSelectAtomsContext.Provider>
                        </TooltipContainerContext.Provider>
                        <TooltipContainer ref={setTooltipContainer} />
                      </DndProvider>
                    </ApolloProvider>
                  </WebGlobalContext.Provider>
                </StyledSnackbarProvider>
              </HelmetProvider>
            </Suspense>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </Provider>
  );
}

function WrappedBrowserApp() {
  const authSessionChecked = useSelector(getAuthSessionChecked);
  const loggingOut = useSelector(getLoggingOut);

  if (loggingOut) {
    return <Loading />;
  }

  return (
    <Switch>
      <Route path={PublicVideoRouting.paths}>
        <AppWrapper>
          <PublicVideoRouting.Component />
        </AppWrapper>
      </Route>
      <Route>
        {authSessionChecked ? <BrowserApp /> : <Loading />}
      </Route>
    </Switch>
  );
}

function BrowserApp() {
  const { data } = useSuspenseQuery(UserProfileQuery, { errorPolicy: 'all' });
  const user = data?.me;

  useUserProfileSubscription();

  return (
    <AppWrapper>
      {user && (
        <TeamSubscriptionManager key={user.id} />
      )}
      <Switch>
        <Route path='/verify' component={VerifyCallback} />
        <Route path='/passwordreset' component={PasswordReset} />
        <Route path='/callback' component={LoginCallback} />
        <Route path={InvitationRouting.paths}>
          {user ? (
            <TeamInjector>
              <InvitationRouting.Component />
            </TeamInjector>
          ) : (
            <Login />
          )}
        </Route>
        {!user && (
          <Route path={SquadRouting.path.teamVideo} component={TeamVideoRoutePublicFallbackWithErrorBounds} />
        )}
        <Route path={SquadRouting.paths}>
          {user ? (
            <WrappedTeamRoute>
              <SquadRouting.Component />
            </WrappedTeamRoute>
          ) : (
            <Login />
          )}
        </Route>
        <Route path={LegacyPigeonRouting.paths}>
          {user ? (
            <LegacyPigeonRouting.Component />
          ) : (
            <Login />
          )}
        </Route>
        <Route path='/'>
          {user ? (
            <Redirect to={SquadRouting.createUrl.base()} />
          ) : (
            <Login />
          )}
        </Route>
      </Switch>
    </AppWrapper>
  );
}

const languageCodes = [
  'de',
  'en',
  'es',
  'fr',
  'ja',
  'ko',
  'pt',
  'ru',
  'tr',
  'vi',
  'zh',
] as const;

function WrappedTeamRoute({ children }: PropsWithChildren<{}>) {
  const portalNode = useMemo(() => portals.createHtmlPortalNode(), []);
  const [detailedView, setDetailedView] = useState(true);
  const dispatch = useDispatch();
  const handleCollapse = () => setDetailedView(false);
  const handleExpand = () => setDetailedView(true);
  const handleClearUploadedUploads = () => {
    dispatch(clearUploadedVideosTemp());
    dispatch(clearFailedUploads());
  };
  return (
    <ErrorBoundary FallbackComponent={SquadError}>
      <SelectedTeamContext.Provider value={useSelectedTeamId()}>
        <TeamInjector>
          <Stack direction='row' flex={1} overflow='hidden' sx={{ background: 'inherit', backgroundAttachment: 'fixed' }}>
            <Stack
            sx={{
              overflowY: 'hidden',
              backgroundColor: theme => theme.palette.background.paper,
              background: theme => theme.palette.gradient.background?.navigation,
              width: 76,
            }}
            >
              <TeamSelector />
            </Stack>
            <UserPortalContext.Provider value={portalNode}>
              {children}
            </UserPortalContext.Provider>
          </Stack>
          <portals.InPortal node={portalNode}>
            <UserControls />
          </portals.InPortal>
          <AbortUploadContext.Provider value={useAbortUploadContextValue({ onCollapse: handleCollapse })}>
            <Box sx={{ position: 'fixed', m: 2, bottom: 0, right: 0, zIndex: theme => theme.zIndex.modal }}>
              <UploadQueue detailedView={detailedView} onCollapse={handleCollapse} onExpand={handleExpand} onClearTemp={handleClearUploadedUploads} />
            </Box>
          </AbortUploadContext.Provider>
        </TeamInjector>
      </SelectedTeamContext.Provider>
    </ErrorBoundary>
  );
}

function UserControls(props: { sx?: SxProps<Theme> } & Omit<StackProps, 'ref'>) {
  const handleLogout = useLogout();
  const { t } = useTranslation(['languages']);
  const popupState = usePopupState({ variant: 'popover', popupId: 'language-menu' });
  return (
    <Stack
    direction='row'
    width='min-content'
    justifyContent='flex-end'
    marginLeft='auto'
    marginTop={2}
    marginRight={0.5}
    spacing={1}
    {...props}
    >
      <IconButton {...bindTrigger(popupState)}>
        <LanguageIcon />
      </IconButton>
      <Menu {...bindMenu(popupState)}>
        {languageCodes.map(lang => (
          <MenuItem
          key={lang}
          onClick={() => {
            i18next.changeLanguage(lang);
            popupState.close();
          }}
          >
            <ListItemText>
              {t(`languages:untranslated.${lang}`)}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
      <IconButton onClick={handleLogout}>
        <ExitToAppIcon />
      </IconButton>
    </Stack>
  );
}

function Login() {
  const { t } = useTranslation(['common']);
  return (
    <Fragment>
      <Helmet>
        <title>
          {t('common:authentication.login')} - {process.env.REACT_APP_APPNAME}
        </title>
      </Helmet>
      <LoginDialog open={true} />
    </Fragment>
  );
}
